import Services from "../../../services";

class RegisterSalesOrderApi {
  getSales() {
    return Services.get("/karyawan");
  }
  getCustomer() {
    return Services.get("/customer");
  }
  getItemBarang() {
    return Services.get("/barang");
  }
  getStokItemBarang(params) {
    return Services.get("/barang/stok/", { params });
  }
  getJenisCustomer() {
    return Services.get("/customer/jenis");
  }
  getProvinsi() {
    return Services.get("/customer/provinsi");
  }
  getKabupaten(id_provinsi) {
    return Services.get(`/customer/kabupaten?id_provinsi=${id_provinsi}`);
  }
  getKecamatan(id_kabupaten) {
    return Services.get(`/customer/kecamatan?id_kabupaten=${id_kabupaten}`);
  }
  getDesa(id_kecamatan) {
    return Services.get(`/customer/desa?id_kecamatan=${id_kecamatan}`);
  }
  getBank() {
    return Services.get(`/customer/bank`);
  }
  createCustomer(data) {
    return Services.post("/customer", data);
  }
}

export default new RegisterSalesOrderApi();

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import _ from "lodash";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  Tr,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  BackButton,
  FilterButton,
} from "components";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";
import { FakturPenjualanApi } from "api";
import { IoAdd, IoAddOutline } from "react-icons/io5";
import { ModalFilter } from "./Section";

const ListSalesOrderFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSalesOrderFakturPenjualan, setDataSalesOrderFakturPenjualan] =
    useState([]);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.so?.filter?.active,
      tgl_sales_order_mulai: location?.state?.so?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai:
        location?.state?.so?.filter?.tgl_sales_order_selesai,
      customer: location?.state?.so?.filter?.customer,
      sales: location?.state?.so?.filter?.sales,
    },
    pagination: {
      page: location?.state?.so?.pagination?.page ?? "1",
      dataLength: location?.state?.so?.pagination?.dataLength ?? "10",
      totalPage: location?.state?.so?.pagination?.totalPage ?? "1",
      dataCount: location?.state?.so?.pagination?.dataCount ?? "0",
    },
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    FakturPenjualanApi.getSO({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      customer: dataFilter?.filter?.customer,
      sales: dataFilter?.filter?.sales,
    })
      .then((res) => {
        setDataSalesOrderFakturPenjualan(res?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Faktur Penjualan");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.sales,
  ]);

  const PageContent = () => {
    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {`${brg.no_surat_jalan ?? "-"} | ${brg.nama_item ?? "-"} ${
                      DecimalConvert(parseFloat(brg.qty ?? 0)).getWithComa
                    } ${brg?.nama_satuan}`}
                  </li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>
                      {`${brg.no_surat_jalan ?? "-"} | ${
                        brg.nama_item ?? "-"
                      } ${
                        DecimalConvert(parseFloat(brg.qty ?? 0)).getWithComa
                      } ${brg?.nama_satuan}`}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List SO yang Siap Dijadikan Faktur Penjualan</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Sales Order</ThFixed>
              <Th>Customer</Th>
              <Th>Sales</Th>
              <Th>Barang Jadi</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataSalesOrderFakturPenjualan.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_sales_order
                      ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val.no_sales_order ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_sales ?? "-"}</Td>
                <Td style={{ minWidth: "170px" }}>
                  {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    "-"
                  )}
                  {/* {val.barang_jadi ?? "-"} */}
                </Td>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    {/* <ReadButton onClick={() => setModalConfig({ show: true, data: val })} /> */}
                    <ActionButton
                      size="sm"
                      // text="Buat Faktur"
                      className="text-nowrap"
                      onClick={() =>
                        history.push(
                          "/sls/transaksi/faktur-penjualan/tambah/" +
                            val.id_surat_jalan,
                          { ...location.state, so: dataFilter, data: val }
                        )
                      }
                    >
                      <IoAddOutline />
                    </ActionButton>
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (
      !dataSalesOrderFakturPenjualan ||
      dataSalesOrderFakturPenjualan.length < 1
    ) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() =>
              history.push("/sls/transaksi/faktur-penjualan", {
                ...location.state,
                so: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default ListSalesOrderFakturPenjualan;

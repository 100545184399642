// React
import React, {useState, useEffect} from 'react'

// Form
import { Formik } from 'formik'

// Components
import { 
	FilterModal, DatePicker, SelectSearch 
} from 'components'
import { DateConvert } from 'utilities'

// API
import Axios from "axios"
import { SalesOrderSPKApi } from 'api'

const ModalFilterRAB = ({ show, setShow, data, setData }) => {
	// STATE DATA
	const [dataCustomer, setDataCustomer] = useState([{label: 'Semua', value: undefined}])
	const baseline = [
		{
			label: 'Semua',
			value: undefined
		},
		{
			label: '1',
			value: '1'
		},
		{
			label: '2',
			value: '2'
		},
		{
			label: '3',
			value: '3'
		},
		{
			label: '4',
			value: '4'
		},
		{
			label: '5',
			value: '5'
		},
		{
			label: '6',
			value: '6'
		},
		{
			label: '7',
			value: '7'
		},
		{
			label: '8',
			value: '8'
		},
		{
			label: '9',
			value: '9'
		},
		{
			label: '10',
			value: '10'
		}
	]
	const [loading, setLoading] = useState(true)

	// REQUSET DATA SERVER
	const getDataDropdown = () => {
		Axios.all([
			SalesOrderSPKApi.getDropdownCustomer()
		])
		.then(Axios.spread((customer) => {
			const mapDataCustomer = customer?.data?.data ? customer.data.data.map(val => ({label: val?.nama_customer, value: val?.id_customer})) : []

			setDataCustomer([...dataCustomer, ...mapDataCustomer])
		}))
		.finally(() => setLoading(false))
	}

	// USE EFFECT ALL DROPDOWN
    useEffect(() => {
		getDataDropdown()

		// eslint-disable-next-line
    }, [])

	// FORM VALUES
	const formInitialValues = {
		tgl_rab_mulai: data.tgl_rab_mulai,
		tgl_rab_selesai: data.tgl_rab_selesai,
		tgl_rae_mulai: data.tgl_rae_mulai,
		tgl_rae_selesai: data.tgl_rae_selesai,
		customer: data.customer,
		baseline: data.baseline
	}
	const formSubmitHandler = (values) => {
		const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

		setData({
			...data,
			...values,
			active: checkActive,
			page: 1
		})

		setShow(false)
	}

	// HANDLE CHANGE 
	const onTanggalSOChange = (dates, values, setValues) => {
		const [start, end] = dates
		const startDate = start ? DateConvert(start).default : null
		const endDate = end ? DateConvert(end).default : null

		setValues({
			...values,
			tgl_rae_mulai: startDate,
			tgl_rae_selesai: endDate,
		})
	}
	const onTanggalRABChange = (dates, values, setValues) => {
		const [start, end] = dates
		const startDate = start ? DateConvert(start).default : null
		const endDate = end ? DateConvert(end).default : null

		setValues({
			...values,
			tgl_rab_mulai: startDate,
			tgl_rab_selesai: endDate,
		})
	}
	const onResetButtonClick = (_values, setValues) => {
		setValues({
			tgl_rab_mulai: undefined,
			tgl_rab_selesai: undefined,
			tgl_rae_mulai: undefined,
			tgl_rae_selesai: undefined,
			customer: undefined,
			baseline: undefined
		})
	}

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValues}
			onSubmit={formSubmitHandler}
		>	
			{({values, handleSubmit, setFieldValue, setValues}) => (
				<FilterModal
					show={show}
					setShow={setShow}
					onResetButtonClick={() => onResetButtonClick(values, setValues)}
					onFilterButtonClick={handleSubmit}
				>		
					<DatePicker 
						selectsRange
						label="Tgl. RAB"
						placeholderText="Pilih Tanggal RAB"
						startDate={values.tgl_rab_mulai ? new Date(values.tgl_rab_mulai) : ''}
						endDate={values.tgl_rab_selesai ? new Date(values.tgl_rab_selesai) : ''}
						onChange={dates => onTanggalRABChange(dates, values, setValues)}
						monthsShown={2}
					/>

					<DatePicker 
						selectsRange
						label="Tgl. Unit Cost"
						placeholderText="Pilih Tanggal unit cost"
						startDate={values.tgl_rae_mulai ? new Date(values.tgl_rae_mulai) : ''}
						endDate={values.tgl_rae_selesai ? new Date(values.tgl_rae_selesai) : ''}
						onChange={dates => onTanggalSOChange(dates, values, setValues)}
						monthsShown={2}
					/>

					<SelectSearch 
						key={values.customer}
						label="Customer"
						placeholder="Pilih customer"
						defaultValue={dataCustomer.find(val => val.value === values.customer)}
						option={dataCustomer}
						onChange={val => setFieldValue('customer', val.value)}
						loading={loading}
					/>

					<SelectSearch
						key={values.baseline}
						label="Baseline"
						placeholder="Pilih Baseline"
						defaultValue={baseline.find(val => val.value === values.baseline)}
						option={baseline}
						onChange={val => setFieldValue('baseline', val.value)}
						loading={loading}
					/>
				</FilterModal>
			)}
		</Formik>
	)
}
export default ModalFilterRAB
import React from "react";
import { Table, Tr, Td } from "components";
import { RupiahConvert } from "utilities";

const KreditLimit = ({ total, dataSPK }) => {
  const Calculate = () => {
    const totalDenganAkumulasi = parseInt(total) + parseInt(dataSPK.akumulasi);
    return totalDenganAkumulasi;
  };
  return (
    <>
      <small>Kredit Limit</small>
      <br />

      <Table>
        <Tr>
          <Td textRight>
            <b>Akumulasi Piutang Customer</b>
          </Td>
          <Td textRight>
            {RupiahConvert(parseFloat(dataSPK.akumulasi ?? 0).toString()).getWithComa}
          </Td>
        </Tr>
        <Tr>
          <Td textRight>
            <b>Akumulasi Piutang Customer + Total Sales Order</b>
          </Td>
          <Td textRight>{RupiahConvert(parseFloat(Calculate()).toString()).getWithComa}</Td>
        </Tr>
        <Tr>
          <Td textRight>
            <b>Kredit Limit Customer</b>
          </Td>
          <Td textRight>
            {RupiahConvert(parseFloat(dataSPK.kredit_limit ?? 0).toString()).getWithComa}
          </Td>
        </Tr>
        <Tr>
          <Td textRight colSpan={2}>
            {Calculate() >= dataSPK.kredit_limit ? (
              <b style={{ color: "red" }}>TRANSAKSI MELEBIHI KREDIT LIMIT</b>
            ) : (
              <b style={{ color: "green" }}>TRANSAKSI DIBAWAH KREDIT LIMIT</b>
            )}
          </Td>
        </Tr>
      </Table>
    </>
  );
};

export default KreditLimit;

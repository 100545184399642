import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Alert, BackButton, ActionButton, DataStatus } from "components";
import { Form, InfoSection, TableBarangJadi } from "../Section";
import { SalesOrderSPKApi } from "api";

const DetailSOSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_sales_order_spk } = useParams();
  const [dataSOSPK, setDataSOSPK] = useState({});
  const [dataKaryawan, setDataKaryawan] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([
      SalesOrderSPKApi.getSingleSOSPK({ id_sales_order_spk }),
      SalesOrderSPKApi.getKaryawan(),
    ])
      .then(
        Axios.spread((resSingle, resKaryawan) => {
          const data = resSingle?.data?.data ?? {};
          const karyawan = resKaryawan?.data?.data?.map((item) =>
            Object({
              ...item,
              value: item.id_karyawan,
              label: item.nama_karyawan,
            })
          );
          const barangJadi = data?.detail?.map((item) =>
            Object({
              ...item,
              kode_item: item.kode_barang,
              nama_item: item.nama_barang,
              satuan: item.kode_satuan,
              qty: parseFloat(item.qty_sales_order_spk),
              harga_satuan: item.unit_cost,
            })
          );

          setDataSOSPK(data);
          setDataKaryawan(karyawan);
          setDataBarangJadi(barangJadi);
        })
      )
      .catch((err) => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const formInitialValues = {
    id_sales_order_spk: id_sales_order_spk,
    tgl_sales_order_spk: dataSOSPK?.tgl_sales_order_spk ?? "",
    no_sales_order_spk: dataSOSPK?.no_sales_order_spk ?? "",
    batas_waktu: dataSOSPK?.batas_waktu ?? "",
    id_sales: dataSOSPK?.id_sales ?? "",
    diskon: parseFloat(dataSOSPK?.diskon ?? 0),
    ppn: parseFloat(dataSOSPK?.ppn ?? 0),
  };
  const formValidationSchema = Yup.object().shape({
    tgl_sales_order_spk: Yup.string().required("Pilih tanggal Sales Order SPK"),
    no_sales_order_spk: Yup.string().required(
      "Pilih tanggal untuk menentukan nomor Sales Order SPK"
    ),
    batas_waktu: Yup.string().required("Pilih tanggal Batas Waktu"),
    id_sales: Yup.string().required("Pilih Sales"),
  });
  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      barang_jadi: dataBarangJadi,
    };

    SalesOrderSPKApi.save(finalValues)
      .then(() =>
        history.push("/transaksi/penawaran", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          },
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          type: "danger",
          text: "Data gagal ditambah!",
        });
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  useEffect(() => {
    setNavbarTitle("Sales Order SPK");
    getInitialData();
  }, []);

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Sales Order SPK</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataSOSPK?.stakeholder?.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval === "VER" || val.status_approval === "REV"
                      ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                      : val.status_approval === "APP"
                      ? "Pengesah"
                      : val.status_approval === "PEN"
                      ? "Pembuat"
                      : "Di Tolak Oleh"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Sales Order SPK</b>
          <div className="d-flex align-items-center justify-content-top">
            <BackButton onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataSOSPK} />
              <hr />
              <Form type="DETAIL" formik={formik} dataKaryawan={dataKaryawan} />
              <TableBarangJadi
                type="DETAIL"
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                diskon={formik.values.diskon}
                ppn={formik.values.ppn}
                setFieldValue={formik.setFieldValue}
                dataSPK={dataSOSPK}
              />
            </>
          )}
        </Card.Body>
      </Card>

      {Boolean(!isPageLoading && !isFetchingFailed) && <CatatanApproval />}
    </>
  );
};

export default DetailSOSPK;

import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Alert, BackButton, ActionButton, DataStatus } from "components";
import { Form, InfoSection, TableBarangJadi } from "../Section";
import { SalesOrderSPKApi } from "api";

const TambahSOSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_spk } = useParams();
  const { state } = useLocation();
  const id_unit_produksi = sessionStorage.getItem("id_unit_produksi");
  const [dataSPK, setDataSPK] = useState({});
  const [dataKaryawan, setDataKaryawan] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([
      SalesOrderSPKApi.getSingleSPK({ id_spk }),
      SalesOrderSPKApi.getKaryawan(),
    ])
      .then(
        Axios.spread((resSingle, resKaryawan) => {
          const data = resSingle?.data?.data ?? {};
          const karyawan = resKaryawan?.data?.data?.map((item) =>
            Object({
              ...item,
              value: item.id_karyawan,
              label: item.nama_karyawan,
            })
          );
          const barangJadi = data?.detail_barang_jadi?.map((item) =>
            Object({
              ...item,
              satuan: item.satuan,
              qty: parseFloat(item.qty),
              harga_satuan: item.rounded,
            })
          );

          setDataSPK(data);
          setDataKaryawan(karyawan);
          setDataBarangJadi(barangJadi);
        })
      )
      .catch((err) => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const isValidGudang = () => {
    const checkData = dataBarangJadi?.map((item) =>
      item.id_gudang ? true : false
    );
    const isValid = checkData.every((item) => item === true);
    if (!isValid) {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Gudang pada Item Barang Jadi harus diisi!",
      });
    }
    return isValid;
  };

  const formInitialValues = {
    id_spk: id_spk,
    tgl_sales_order_spk: "",
    no_sales_order_spk: "",
    batas_waktu: "",
    id_sales: "",
    diskon: parseFloat(dataSPK?.diskon_penawaran ?? 0),
    ppn: parseFloat(dataSPK?.ppn_penawaran ?? 0),
  };
  const formValidationSchema = Yup.object().shape({
    tgl_sales_order_spk: Yup.string().required("Pilih tanggal Sales Order SPK"),
    no_sales_order_spk: Yup.string().required(
      "Pilih tanggal untuk menentukan nomor Sales Order SPK"
    ),
    batas_waktu: Yup.string().required("Pilih tanggal Batas Waktu"),
    id_sales: Yup.string().required("Pilih Sales"),
  });

  console.log(dataBarangJadi);
  const formSubmitHandler = (values, { setSubmitting }) => {
    const mapBarangJadi = dataBarangJadi?.map((item) =>
      Object({
        id_item_buaso: item.id_barang_jadi,
        id_gudang: item.id_gudang,
        qty_sales_order_spk: item.qty,
        unit_cost: parseFloat(item.harga_satuan),
      })
    );

    const calculateItemProfit = (harga_satuan) => {
      const harga = parseFloat(harga_satuan ?? 0);
      return harga + (harga * 10) / 100;
    };

    const grandTotal = dataBarangJadi.reduce((acc, { harga_satuan, qty }) => {
      const subtotal = calculateItemProfit(harga_satuan) * parseFloat(qty ?? 0);
      return parseFloat(acc + subtotal);
    }, 0);

    const calculateDiskon = () => {
      const totalDiskon = (values.diskon * grandTotal) / 100;
      const totalSetelahDiskon = grandTotal - totalDiskon;

      return {
        totalDiskon: parseFloat(totalDiskon),
        totalSetelahDiskon: parseFloat(totalSetelahDiskon),
      };
    };

    const totalDenganAkumulasi = () => {
      const totalSetelahDiskon = calculateDiskon().totalSetelahDiskon;
      const totalPPN = (values.ppn * totalSetelahDiskon) / 100;
      const totalSetelahPPN = totalSetelahDiskon + totalPPN;

      return parseFloat(totalSetelahPPN) + parseFloat(dataSPK.akumulasi);
    };

    const finalValues = {
      ...values,
      id_karyawan: values.id_sales,
      detail: mapBarangJadi,
    };

    if (totalDenganAkumulasi() >= dataSPK.kredit_limit) {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "TRANSAKSI TIDAK DAPAT DISIMPAN KARENA MELEBIHI KREDIT LIMIT",
      });
      setSubmitting(false);
    } else {
      SalesOrderSPKApi.create(finalValues)
        .then(() =>
          history.push("/transaksi/sales-order-spk/list-spk", {
            ...state,
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal ditambah!",
          });
        });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  useEffect(() => {
    setNavbarTitle("Sales Order SPK");
    getInitialData();
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Tambah Data Sales Order SPK</b>
        <div className="d-flex align-items-center justify-content-top">
          <BackButton onClick={() => history.goBack()} />
        </div>
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
        ) : (
          <>
            <InfoSection data={dataSPK} />
            <hr />
            <Form type="CREATE" formik={formik} dataKaryawan={dataKaryawan} />
            <TableBarangJadi
              dataBarangJadi={dataBarangJadi}
              setDataBarangJadi={setDataBarangJadi}
              diskon={formik.values.diskon}
              ppn={formik.values.ppn}
              setFieldValue={formik.setFieldValue}
              dataSPK={dataSPK}
            />
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() => setAlertConfig({ ...alertConfig, show: false })}
            />
            <div className="mt-4 d-flex justify-content-end align-items-center">
              <ActionButton
                text="Tambah Data"
                loading={formik.isSubmitting}
                onClick={() => isValidGudang() && formik.handleSubmit()}
              />
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahSOSPK;

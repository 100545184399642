import Services from "../../../services";

class FakturPenerimaanPenjualanApi {
  getPage(page, dataLength) {
    return Services.get(`/faktur_penjualan_penerimaan/page/?page=${page}&per_page=${dataLength}`);
  }
  getSingle(id_faktur_penjualan) {
    return Services.get(
      `/faktur_penjualan_penerimaan/single?id_faktur_penjualan=${id_faktur_penjualan}`
    );
  }
  getNomorFakturTerima(tanggal) {
    return Services.get(`/faktur_penjualan_penerimaan/no_baru?tanggal=${tanggal}`);
  }
  createFakturPenerimaan(data) {
    return Services.post("/faktur_penjualan_penerimaan", data);
  }
  updateFakturPenerimaan(data) {
    return Services.put("/faktur_penjualan_penerimaan", data);
  }
  deleteFaktur(id_faktur_penjualan) {
    // TODO : DELETE FAKTUR PENJUALAN
    return Services.post("/faktur_penjualan_penerimaan/delete", id_faktur_penjualan);
  }
  search(key) {
    return Services.get(`faktur_penjualan_penerimaan/page?q=${key}`);
  }
}

export default new FakturPenerimaanPenjualanApi();

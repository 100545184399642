import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton,
  FilterButton,
} from "components";
import {
  DateConvert,
  DecimalConvert,
  PageNumber as TableNumber,
} from "utilities";
import { SalesOrderSPKApi } from "api";
import { ModalFilter } from "./Section";

const SalesOrderSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.so?.filter?.active,
      tgl_sales_order_mulai: location?.state?.so?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai:
        location?.state?.so?.filter?.tgl_sales_order_selesai,
      tgl_rab_mulai: location?.state?.so?.filter?.tgl_rab_mulai,
      tgl_rab_selesai: location?.state?.so?.filter?.tgl_rab_selesai,
      customer: location?.state?.so?.filter?.customer,
      proyek: location?.state?.so?.filter?.proyek,
      peringkat_peluang: location?.state?.so?.filter?.peringkat_peluang,
      status_approval: location?.state?.so?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.so?.filter?.page ?? "1",
      dataLength: location?.state?.so?.filter?.dataLength ?? "10",
      totalPage: location?.state?.so?.filter?.totalPage ?? "1",
      dataCount: location?.state?.so?.filter?.dataCount ?? "0",
    },
  });

  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  //menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    SalesOrderSPKApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      tgl_rab_mulai: dataFilter?.filter?.tgl_rab_mulai,
      tgl_rab_selesai: dataFilter?.filter?.tgl_rab_selesai,
      customer: dataFilter?.filter?.customer,
      proyek: dataFilter?.filter?.proyek,
      peringkat_peluang: dataFilter?.filter?.peringkat_peluang,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then(({ data }) => {
        setData(data?.data ?? {});

        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
        setShowAlert(true);
      }, 750)
    );
  };

  useEffect(() => {
    setNavbarTitle("Sales Order SPK (SO SPK)");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.tgl_rab_mulai,
    dataFilter?.filter?.tgl_rab_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.proyek,
    dataFilter?.filter?.peringkat_peluang,
    dataFilter?.filter?.status_approval,
  ]);

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP") {
        return {
          variant: "outline-success",
          label: "APPROVED",
        };
      }
      if (convert === "VER") {
        return {
          variant: "outline-success",
          label: "VERIFIED",
        };
      }
      if (convert === "REV") {
        return {
          variant: "outline-warning",
          label: "REVISI",
        };
      }
      if (convert === "REJ") {
        return {
          variant: "outline-danger",
          label: "REJECT",
        };
      }

      return {
        variant: "outline-secondary",
        label: "PENDING",
      };
    };

    const getStatusProgress = (status) => {
      switch (status) {
        case "close":
          return {
            label: "CLOSED",
            color: "outline-danger",
          };
        case "onprogress":
          return {
            label: "ON PROGRESS",
            color: "outline-warning",
          };
        default:
          return {
            label: "PENDING",
            color: "outline-secondary",
          };
      }
    };

    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      const getKodeSatuan = (brg) => {
        const qty = parseFloat(brg.qty_sales_order_spk ?? 0);

        if (brg?.nama_satuan?.toLowerCase() === "kilometer")
          return `x ${qty} km`;
        return brg?.kode_satuan
          ? `x ${DecimalConvert(qty).getWithComa} ${brg?.kode_satuan}`
          : "";
      };

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>{`${
                    brg.nama_barang
                  } ${getKodeSatuan(brg)}`}</li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>{`${
                      brg.nama_barang
                    } ${getKodeSatuan(brg)}`}</li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Sales Order SPK (SO SPK)</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Sales Order SPK</ThFixed>
              <ThFixed>Informasi SPK</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <ThFixed>Status Progress</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    <ReadButton
                      size="sm"
                      className="mr-1"
                      onClick={() =>
                        history.push(
                          `/transaksi/sales-order-spk/detail/${val.id_sales_order_spk}`
                        )
                      }
                    />
                    {val.status_approval === "REV" && (
                      <UpdateButton
                        size="sm"
                        onClick={() =>
                          history.push(
                            `/transaksi/sales-order-spk/ubah/${val.id_sales_order_spk}`
                          )
                        }
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_sales_order_spk
                      ? DateConvert(new Date(val.tgl_sales_order_spk))
                          .defaultDMY
                      : ""}
                  </div>
                  <div>{val.no_sales_order_spk ?? ""}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_spk
                      ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                      : ""}
                  </div>
                  <div>{val.no_spk ?? ""}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>
                  {val.nama_barang && val.nama_barang?.length > 0 ? (
                    <BarangJadiCollapse data={val.nama_barang} />
                  ) : (
                    "-"
                  )}
                </Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={getStatusProgress(val.status).color}
                  >
                    {getStatusProgress(val.status).label}
                  </ApprovalStatusButton>
                </TdFixed>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={checkStatus(val.status_approval).variant}
                  >
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ActionButton
            text="Tambah Data"
            onClick={() =>
              history.push("/transaksi/sales-order-spk/list-spk", {
                ...location.state,
                so: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default SalesOrderSPK;

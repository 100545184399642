import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { CloseSalesOrderApi, SalesOrderSPKApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataBarangJadi, setDataBarangJadi] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [dataCustomer, setDataCustomer] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [dataStatusProgress, setDataBarangJdataStatusProgress] = useState([
    { label: "Semua", value: undefined },
    { label: "ON PROGRESS", value: "on progress" },
    { label: "CLOSED", value: "closed" },
  ]);
  const [loading, setLoading] = useState(true);
  // FORM VALUES
  const formInitialValues = {
    tgl_sales_order_mulai: data?.filter?.tgl_sales_order_mulai,
    tgl_selesai_order_sales: data?.filter?.tgl_selesai_order_sales,
    id_customer: data?.filter?.id_customer,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  const onTanggalSalesOrderChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_sales_order_mulai: startDate,
      tgl_selesai_order_sales: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_sales_order_mulai: undefined,
      tgl_selesai_order_sales: undefined,
      id_customer: undefined,
      barang_jadi: undefined,
    });
  };

  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([CloseSalesOrderApi.getDropdownCustomer()])
      .then(
        Axios.spread((id_customer) => {
          const mapDataCustomer = id_customer?.data?.data
            ? id_customer.data.data.map((val) => ({
                label: val?.nama_customer,
                value: val?.id_customer,
              }))
            : [];

          setDataCustomer([...dataCustomer, ...mapDataCustomer]);
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDataDropdown();

    return () => {};
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Sales Order"
            placeholderText="DD/MM/YYYY - DD/MM/YYYY"
            startDate={
              values.tgl_sales_order_mulai
                ? new Date(values.tgl_sales_order_mulai)
                : ""
            }
            endDate={
              values.tgl_selesai_order_sales
                ? new Date(values.tgl_selesai_order_sales)
                : ""
            }
            onChange={(dates) =>
              onTanggalSalesOrderChange(dates, values, setValues)
            }
            monthsShown={2}
          />

          <SelectSearch
            key={values.id_customer}
            label="Customer"
            placeholder="Pilih Item Produksi"
            defaultValue={dataCustomer.find(
              (val) => val.value === values.id_customer
            )}
            option={dataCustomer}
            onChange={(val) => setFieldValue("id_customer", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;

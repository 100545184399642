// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DataStatus,
  Tr,
  Th,
  Alert,
  Pagination,
  THead,
  TBody,
  UpdateButton,
  ReadButton,
  TdFixed,
  StatusModal,
  ThFixed,
  FilterButton,
  Td,
  ApprovalStatusButton,
} from "../../../components";
import { DateConvert, DecimalConvert, PageNumber } from "../../../utilities";
import { ButtonGroup } from "react-bootstrap";

// API
import { TransaksiSalesOrderApi } from "../../../api";

// View Components
import { ModalFilter } from "./Section";

const TransaksiSalesOrder = ({ setNavbarTitle }) => {
  const title = "Sales Order";
  // USE HISTORY
  const history = useHistory();

  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;
  const filter = location?.state?.so ?? {};
  const rab = location?.state?.rab ?? {};

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // MODALS SHOW STATE
  const [isStatusModal, setIsStatusModal] = useState({
    show: false,
    title: " ",
  }); // STATUS MODAL STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    page: filter.page ?? 1,
    per_page: filter.per_page ?? 10,
    q: filter.q ?? "",
    active: filter.active,
    tgl_sales_order_mulai: filter.tgl_sales_order_mulai,
    tgl_sales_order_selesai: filter.tgl_sales_order_selesai,
    tgl_rab_mulai: filter.tgl_rab_mulai,
    tgl_rab_selesai: filter.tgl_rab_selesai,
    customer: filter.customer,
    proyek: filter.proyek,
    peringkat_peluang: filter.peringkat_peluang,
    status_approval: filter.status_approval,
  });

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    const filterData = { ...dataFilter };
    delete filterData.active;

    TransaksiSalesOrderApi.page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);

        filterData.q !== "" &&
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${filterData.q}`,
          });
        filterData.q !== "" ? setShowAlert(true) : setShowAlert(false);
      });
  };

  //function Input searchTime
  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setDataFilter({
          ...dataFilter,
          q: key,
        });
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true);
      }, 750)
    );
  };

  // FUNCTION KONVERSI TANGGAL INPUT
  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(" ");
      const date = new Date(getDate[0]);
      return `${DateConvert(date).defaultDay}/${
        DateConvert(date).defaultMonth
      }/${DateConvert(date).defaultYear}`;
      // return DateConvert(date).detail
    }
    return "-";
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig);
      setShowAlert(true);
    }

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line
  }, [dataFilter]);

  // MODAL HAPUS COMPONENT
  // const HapusModal = () => {
  //   // MENANGANI DELETE BUTTON LOADING
  //   const [btnLoading, setBtnLoading] = useState(false)

  //   // DELETE DATA DARI SERVER
  //   const deleteDataHandler = () => {
  //     setBtnLoading(true)

  //     TransaksiSalesOrderApi.deleteSalesOrder(deleteData.id_sales_order)
  //       .then(() => {
  //         // KONFIGURASI ALERT
  //         setAlertConfig({
  //           variant: "primary",
  //           text: "Hapus data berhasil!",
  //         })
  //       })
  //       .catch((err) => {
  //         // KONFIGURASI ALERT
  //         setAlertConfig({
  //           variant: "danger",
  //           text: `Hapus data gagal! (${err.response.data.message})`,
  //         })
  //       })
  //       .finally(() => {
  //         // CLOSE MODAL
  //         setIsDeleteData(false)
  //         // TAMPIL ALERT
  //         setShowAlert(true)
  //         // FETCH DATA DARI SERVER
  //         getData()
  //       })
  //   }

  //   return (
  //     <DeleteModal
  //       show={isDeleteData}
  //       onHide={() => setIsDeleteData(false)}
  //       loading={btnLoading}
  //       onConfirm={deleteDataHandler}
  //       title={title}
  //     >
  //       <div>Nomor Sales Order : {deleteData.no_sales_order}</div>
  //     </DeleteModal>
  //   )
  // }

  // STATUS MODAL COMPONENT
  const Status = () => {
    const [isLoadingStatusData, setIsLoadingStatusData] = useState(false); //TODO : SET TRUE JIKA SUDAH FIX API
    const [statusData, setStatusData] = useState({});

    useEffect(() => {
      setIsLoadingStatusData(true);
      TransaksiSalesOrderApi.getStatusSalesOrder(isStatusModal.no_sales_order)
        .then((res) => {
          setStatusData(res.data.data);
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message ?? ""})`,
          });
        })
        .finally(() => {
          setIsLoadingStatusData(false);
        });

      // eslint-disable-next-line
    }, []);

    // KOMPONEN TABEL STATUS
    const TableStatus = () => {
      return (
        <>
          <CRUDLayout.Table>
            <THead>
              <Tr>
                <ThFixed>No</ThFixed>
                <Th>Nama Proses</Th>
                <Th>Tgl. Proses</Th>
                <ThFixed>No. Dokumen</ThFixed>
              </Tr>
            </THead>
            <TBody>
              <Tr>
                <TdFixed>1</TdFixed>
                <Td>No. Sales Order</Td>
                <TdFixed>
                  {getConvertedDate(statusData.tgl_sales_order)}
                </TdFixed>
                <TdFixed>{statusData?.no_sales_order ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>2</TdFixed>
                <Td>No. Delivery Order</Td>
                <TdFixed>
                  {getConvertedDate(statusData.tgl_delivery_order)}
                </TdFixed>
                <TdFixed>{statusData?.no_delivery_order ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>3</TdFixed>
                <Td>No. Faktur Penjualan</Td>
                <TdFixed>
                  {getConvertedDate(statusData.tgl_faktur_penjualan)}
                </TdFixed>
                <TdFixed>{statusData?.no_faktur_penjualan ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>4</TdFixed>
                <Td>No. Surat Jalan</Td>
                <TdFixed>
                  {getConvertedDate(statusData.tgl_surat_jalan)}
                </TdFixed>
                <TdFixed>{statusData?.no_surat_jalan ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>5</TdFixed>
                <Td>No. Penerimaan Faktur</Td>
                <TdFixed>
                  {getConvertedDate(statusData.tgl_penerimaan_faktur)}
                </TdFixed>
                <TdFixed>{statusData?.no_penerimaan_faktur ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>6</TdFixed>
                <Td>No. Pembatalan Faktur</Td>
                <TdFixed>
                  {getConvertedDate(statusData.tgl_pembatalan_faktur)}
                </TdFixed>
                <TdFixed>{statusData?.no_pembatalan_faktur ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>7</TdFixed>
                <Td>No. Faktur Piutang</Td>
                <TdFixed>
                  {getConvertedDate(statusData.tgl_faktur_piutang)}
                </TdFixed>
                <TdFixed>{statusData?.no_faktur_piutang ?? "-"}</TdFixed>
              </Tr>
            </TBody>
          </CRUDLayout.Table>
        </>
      );
    };

    return (
      <StatusModal
        show={isStatusModal.show}
        status={isStatusModal.status}
        onHide={() => setIsStatusModal({ show: false, status: "PROGRESS" })}
      >
        {isLoadingStatusData ? (
          <DataStatus loading={isLoadingStatusData} text="Memuat Data" />
        ) : (
          <TableStatus />
        )}
      </StatusModal>
    );
  };

  // MAIN TABLE COMPONENT
  const Table = () => {
    const getStatusApproval = (status_approval) => {
      switch (status_approval) {
        case "APP":
          return {
            label: "APPROVED",
            color: "outline-success",
          };
        case "REJ":
          return {
            label: "REJECT",
            color: "outline-danger",
          };
        case "REV":
          return {
            label: "REVISI",
            color: "outline-warning",
          };
        case "VER":
          return {
            label: "VERIFIED",
            color: "outline-success",
          };
        default:
          return {
            label: "PENDING",
            color: "outline-secondary",
          };
      }
    };

    const getStatusProgress = (status) => {
      switch (status) {
        case "close":
          return {
            label: "CLOSED",
            color: "outline-danger",
          };
        case "onprogress":
          return {
            label: "ON PROGRESS",
            color: "outline-warning",
          };
        default:
          return {
            label: "PENDING",
            color: "outline-secondary",
          };
      }
    };
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Sales Order</ThFixed>
              <ThFixed>Informasi RAB</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <ThFixed>Status Progress</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex flex-row justify-content-start">
                    <ButtonGroup size="sm">
                      <ReadButton
                        onClick={() =>
                          history.push(
                            `/sls/transaksi/sales-order/detail/${val.id_sales_order}`,
                            {
                              so: dataFilter,
                              rab,
                            }
                          )
                        }
                      />
                      {val.status_approval === "REV" && (
                        <UpdateButton
                          onClick={() =>
                            history.push(
                              `/sls/transaksi/sales-order/update/${val.id_sales_order}`,
                              {
                                so: dataFilter,
                                rab,
                              }
                            )
                          }
                        />
                      )}
                      {/* {val.mod_status === "EDITABLE" && (
                        <DeleteButton
                          onClick={() => {
                            // setDeleteData(val.id_sales_order)
                            setDeleteData(val)
                            setIsDeleteData(true)
                          }}
                        />
                      )} */}
                    </ButtonGroup>
                  </div>
                </TdFixed>
                <Td>
                  <div>
                    {val.tgl_sales_order
                      ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val.no_sales_order ?? "-"}</div>
                </Td>
                <Td>
                  <div>
                    {val.tgl_rab
                      ? DateConvert(new Date(val.tgl_rab)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val.no_rab ?? "-"}</div>
                </Td>
                <Td>{val.nama_customer}</Td>
                <Td>
                  <div>
                    {val?.detail?.length > 0 ? (
                      <ul className="pl-3">
                        {val?.detail.map((e, i) => (
                          <li key={i}>
                            {e.nama_barang
                              ? `${e.nama_barang} x ${
                                  e.qty_sales_order
                                    ? DecimalConvert(e.qty_sales_order)
                                        .getWithComa
                                    : 0
                                } ${e.kode_satuan}`
                              : "-"}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      "-"
                    )}
                  </div>
                </Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={getStatusProgress(val.status).color}
                  >
                    {getStatusProgress(val.status).label}
                  </ApprovalStatusButton>
                </TdFixed>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={getStatusApproval(val.status_approval).color}
                  >
                    {getStatusApproval(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataNumber={
            dataFilter.page * dataFilter.per_page - dataFilter.per_page + 1
          }
          dataPage={dataFilter.page * dataFilter.per_page}
          dataCount={dataCount}
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              page: 1,
              per_page: e.target.value,
            })
          }
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch} />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <CreateButton
            onClick={() => {
              history.push("/sls/transaksi/sales-order/rab", {
                so: dataFilter,
                rab,
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          history.replace("/sls/transaksi/sales-order", {
            registerAlertConfig: undefined,
          });
          setShowAlert(false);
        }}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      {isStatusModal.show && <Status />}
      {modalFilter && (
        <ModalFilter
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default TransaksiSalesOrder;

import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Alert, BackButton, ActionButton, DataStatus } from "components";
import { Form, InfoSection, TableBarangJadi } from "../Section";
import { SalesOrderSPKApi } from "api";

const UbahSOSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_sales_order_spk } = useParams();
  const id_unit_produksi = sessionStorage.getItem("id_unit_produksi");
  const [dataSOSPK, setDataSOSPK] = useState({});
  const [dataKaryawan, setDataKaryawan] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([
      SalesOrderSPKApi.getSingleSOSPK({ id_sales_order_spk }),
      SalesOrderSPKApi.getKaryawan(),
    ])
      .then(
        Axios.spread((resSingle, resKaryawan) => {
          const data = resSingle?.data?.data ?? {};
          const karyawan = resKaryawan?.data?.data?.map((item) =>
            Object({
              ...item,
              value: item.id_karyawan,
              label: item.nama_karyawan,
            })
          );
          const barangJadi = data?.detail?.map((item) =>
            Object({
              ...item,
              kode_item: item.kode_barang,
              nama_item: item.nama_barang,
              satuan: item.kode_satuan,
              qty: parseFloat(item.qty_sales_order_spk),
              harga_satuan: item.unit_cost,
            })
          );

          setDataSOSPK(data);
          setDataKaryawan(karyawan);
          setDataBarangJadi(barangJadi);
        })
      )
      .catch((err) => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const isValidGudang = () => {
    const checkData = dataBarangJadi?.map((item) =>
      item.id_gudang ? true : false
    );
    const isValid = checkData.every((item) => item === true);
    if (!isValid) {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Gudang pada Item Barang Jadi harus diisi!",
      });
    }
    return isValid;
  };

  const formInitialValues = {
    id_sales_order_spk: id_sales_order_spk,
    id_spk: dataSOSPK?.id_spk ?? "",
    tgl_sales_order_spk: dataSOSPK?.tgl_sales_order_spk ?? "",
    no_sales_order_spk: dataSOSPK?.no_sales_order_spk ?? "",
    batas_waktu: dataSOSPK?.batas_waktu ?? "",
    id_sales: dataSOSPK?.id_sales ?? "",
    diskon: parseFloat(dataSOSPK?.diskon ?? 0),
    ppn: parseFloat(dataSOSPK?.ppn ?? 0),
  };
  const formValidationSchema = Yup.object().shape({
    tgl_sales_order_spk: Yup.string().required("Pilih tanggal Sales Order SPK"),
    no_sales_order_spk: Yup.string().required(
      "Pilih tanggal untuk menentukan nomor Sales Order SPK"
    ),
    batas_waktu: Yup.string().required("Pilih tanggal Batas Waktu"),
    id_sales: Yup.string().required("Pilih Sales"),
  });
  const formSubmitHandler = (values) => {
    const mapBarangJadi = dataBarangJadi?.map((item) =>
      Object({
        id_item_buaso: item.id_item_buaso,
        id_gudang: item.id_gudang,
        qty_sales_order_spk: item.qty_sales_order_spk,
        unit_cost: parseFloat(item.unit_cost),
      })
    );

    const finalValues = {
      ...values,
      id_karyawan: values.id_sales,
      detail: mapBarangJadi,
    };

    SalesOrderSPKApi.update(finalValues)
      .then(() =>
        history.push("/transaksi/sales-order-spk", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          },
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal ditambah!",
        });
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  useEffect(() => {
    setNavbarTitle("Sales Order SPK");
    getInitialData();
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Ubah Data Sales Order SPK</b>
        <div className="d-flex align-items-center justify-content-top">
          <BackButton onClick={() => history.goBack()} />
        </div>
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : isFetchingFailed ? (
          <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
        ) : (
          <>
            <InfoSection data={dataSOSPK} />
            <hr />
            <Form type="UPDATE" formik={formik} dataKaryawan={dataKaryawan} />
            <TableBarangJadi
              dataBarangJadi={dataBarangJadi}
              setDataBarangJadi={setDataBarangJadi}
              diskon={formik.values.diskon}
              ppn={formik.values.ppn}
              setFieldValue={formik.setFieldValue}
              dataSPK={dataSOSPK}
            />

            <div className="mt-4 d-flex justify-content-end align-items-center">
              <ActionButton
                variant="success"
                text="Ubah Data"
                loading={formik.isSubmitting}
                onClick={() => isValidGudang() && formik.handleSubmit()}
              />
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default UbahSOSPK;

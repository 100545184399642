import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IoAdd, IoAddOutline } from "react-icons/io5";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
  FilterButton,
} from "components";
import ModalDetailSPK from "./ModalDetailSPK";
import {
  DateConvert,
  DecimalConvert,
  PageNumber as TableNumber,
} from "utilities";
import { SalesOrderSPKApi } from "api";
import { ModalFilter } from "./Section";

const ListSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.spk?.filter?.active,
      tgl_spk_mulai: location?.state?.spk?.filter?.tgl_spk_mulai,
      tgl_spk_selesai: location?.state?.spk?.filter?.tgl_spk_selesai,
      tgl_penawaran_mulai: location?.state?.spk?.filter?.tgl_penawaran_mulai,
      tgl_penawaran_selesai:
        location?.state?.spk?.filter?.tgl_penawaran_selesai,
      customer: location?.state?.spk?.filter?.customer,
      baseline: location?.state?.spk?.filter?.baseline,
    },
    pagination: {
      page: location?.state?.spk?.pagination?.page ?? "1",
      dataLength: location?.state?.spk?.pagination?.dataLength ?? "10",
      totalPage: location?.state?.spk?.pagination?.totalPage ?? "1",
      dataCount: location?.state?.spk?.pagination?.dataCount ?? "0",
    },
  });

  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} });

  const getInitialData = () => {
    setIsPageLoading(true);

    SalesOrderSPKApi.getListSPK({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_spk_mulai: dataFilter?.filter?.tgl_spk_mulai,
      tgl_spk_selesai: dataFilter?.filter?.tgl_spk_selesai,
      tgl_penawaran_mulai: dataFilter?.filter?.tgl_penawaran_mulai,
      tgl_penawaran_selesai: dataFilter?.filter?.tgl_penawaran_selesai,
      customer: dataFilter?.filter?.customer,
      baseline: dataFilter?.filter?.baseline,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Surat Perjanjian Kerja (SPK)");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_spk_mulai,
    dataFilter?.filter?.tgl_spk_selesai,
    dataFilter?.filter?.tgl_penawaran_mulai,
    dataFilter?.filter?.tgl_penawaran_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.baseline,
  ]);

  const PageContent = () => {
    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
      const getKodeSatuan = (brg) => {
        const qty = parseFloat(brg.qty_sales_order_spk ?? 0);

        if (brg?.nama_satuan?.toLowerCase() === "kilometer")
          return `x ${qty} km`;
        return brg?.kode_satuan
          ? `x ${DecimalConvert(qty).getWithComa} ${brg?.kode_satuan}`
          : "";
      };

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data?.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>{`${
                    brg.nama_item
                  } ${getKodeSatuan(brg)}`}</li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>{`${
                      brg.nama_item
                    } ${getKodeSatuan(brg)}`}</li>
                  )
                );
              }
            })}
          </ul>
          {data?.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Surat Perjanjian Kerja (SPK)</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi SPK</ThFixed>
              <ThFixed>Informasi Penawaran</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_spk)).defaultDMY}</div>
                  <div>{val.no_spk ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {DateConvert(new Date(val.tgl_penawaran)).defaultDMY}
                  </div>
                  <div>{val.no_penawaran ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>
                  {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    "-"
                  )}
                </Td>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <TdFixed textCenter>{val.baseline}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    {/* <ReadButton size='sm' className='my-1 mr-1' onClick={() => setModalConfig({ show: true, data: val })} /> */}
                    <ActionButton
                      size="sm"
                      variant="success"
                      className="my-1"
                      onClick={() =>
                        history.push(
                          `/transaksi/sales-order-spk/tambah/${val.id_spk}`,
                          {
                            ...location.state,
                            spk: dataFilter,
                          }
                        )
                      }
                    >
                      <IoAddOutline />
                    </ActionButton>
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() =>
              history.push("/transaksi/sales-order-spk", {
                ...location.state,
                spk: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      {modalConfig.show && (
        <ModalDetailSPK
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </CRUDLayout>
  );
};

export default ListSPK;

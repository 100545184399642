import { DataStatus, Table, Td, TdFixed, Th } from "components"
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities"

const TableSection = ({ dataTable, dataInfo }) => {
  const values = {
    diskon: dataInfo.diskon,
    ppn: dataInfo.ppn,
  };

  const getTotalFooter = () => {
    const getTotal = () =>
      dataTable.reduce((prev, current) => {
        const total = parseFloat(
          parseFloat(current.qty) * parseFloat(current.harga)
        );

        return prev + total;
      }, 0);

    const getDiskon = () => {
      const total = getTotal();
      const diskon = values.diskon;
      const totalDiskon = (total / 100) * diskon;

      return totalDiskon;
    };

    const getTotalAfterDiskon = () => {
      const total = getTotal();
      const totalDiskon = getDiskon();

      return total - totalDiskon;
    };

    const getPPN = () => {
      const totalAfterDiskon = getTotalAfterDiskon();
      const ppn = values.ppn;
      const totalPPN = (totalAfterDiskon / 100) * ppn;

      return totalPPN;
    };

    const getTotalAfterPPN = () => {
      const ppn = getPPN();
      const totalAfterDiskon = getTotalAfterDiskon();

      return totalAfterDiskon + ppn;
    };

    return {
      total: RupiahConvert(parseFloat(getTotal()).toString()).getWithComa,
      diskon: RupiahConvert(parseFloat(getDiskon()).toString()).getWithComa,
      ppn: RupiahConvert(parseFloat(getPPN()).toString()).getWithComa,
      totalAfterDiskon: RupiahConvert(
        parseFloat(getTotalAfterDiskon()).toString()
      ).getWithComa,
      totalAfterPPN: RupiahConvert(parseFloat(getTotalAfterPPN()).toString())
        .getWithComa,
    };
  };

  const Checkbox = ({ checked, onClick }) => (
    <div className="form-check" onClick={onClick}>
      <input
        type="checkbox"
        className="form-check-input mt-0"
        style={{ width: 20, height: 20 }}
        defaultChecked={checked}
      />
    </div>
  );

  return (
    <>
      <div className="p-1">
        <b>List Surat Jalan</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <Th>Tgl. Surat Jalan</Th>
          <Th>No. Surat Jalan</Th>
          <Th>Referensi Surat Jalan</Th>
          <Th>No. Delivery Order</Th>
          <Th width={300}>Item Barang</Th>
          <Th>Driver</Th>
          <Th>Kendaraan</Th>
          <Th>Qty</Th>
          <Th>Harga Satuan</Th>
          <Th>Jumlah</Th>
        </thead>
        <tbody>
          {dataTable.length > 0 ? (
            dataTable.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>
                  {val.tgl_surat_jalan
                    ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY
                    : "-"}
                </Td>
                <Td>{val.no_surat_jalan}</Td>
                <Td>{val.referensi_surat_jalan ?? "-"}</Td>
                <Td>{val.no_delivery_order}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.driver ?? "-"}</Td>
                <Td>{val.kendaraan ?? "-"}</Td>
                <Td textRight>
                  {DecimalConvert(val.qty).getWithComa} {val.satuan}
                </Td>
                <Td textRight>
                  {RupiahConvert(val.harga.toString()).getWithComa}
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(parseFloat(val.qty * val.harga).toString())
                      .getWithComa
                  }
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
        <tfoot style={{ fontSize: 13 }}>
          {/* Total */}
          <tr className="bg-light">
            <td
              colSpan={9}
              className="p-2 text-right font-weight-bold align-middle"
            >
              Total
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().total}
            </td>
          </tr>
          {/* Diskon */}
          <tr>
            <td colSpan={9} className="py-2 px-1 font-weight-bold text-right">
              Diskon ({DecimalConvert(values.diskon).getWithComa}%)
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().diskon}
            </td>
          </tr>
          {/* Total Setelah Diskon */}
          <tr className="bg-light">
            <td
              colSpan={9}
              className="p-2 text-right font-weight-bold align-middle"
            >
              Total Setelah Diskon
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().totalAfterDiskon}
            </td>
          </tr>
          {/* PPN */}
          <tr>
            <td colSpan={9} className="py-2 px-1 font-weight-bold text-right">
              PPN ({DecimalConvert(values.ppn).getWithComa}%)
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().ppn}
            </td>
          </tr>
          {/* Total Setelah PPN */}
          <tr className="bg-light">
            <td
              colSpan={9}
              className="py-2 px-1 text-right font-weight-bold align-middle"
            >
              Total Setelah PPN
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().totalAfterPPN}
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default TableSection;

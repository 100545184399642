import React, { useState, useEffect } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import {
  CRUDLayout,
  Input,
  InputSearch,
  ExportButton,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  Alert,
  Pagination,
  SelectSearch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  ReadModal,
  ReadModalLarge,
  InputCurrency,
} from "../../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { HargaSatuanJualApi } from "../../../api";
import { DateConvert, PageNumber, RupiahConvert } from "../../../utilities";

const HargaSatuanJual = ({ setNavbarTitle }) => {
  const title = "Harga Satuan Jual";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE
  const [isViewData, setIsViewData] = useState(false); //MODAL VIEW STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [viewData, setViewData] = useState([]);
  // MENYIMPAN DATA DROPDOWN ITEM BAHAN
  const [dataItemBahan, setDataItemBahan] = useState([]);
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const [searchTime, setSearchTime] = useState(null)
  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    // setSearchKey("");

    Axios.all([HargaSatuanJualApi.getPage(page, dataLength, searchKey), HargaSatuanJualApi.getBahan()])
      .then(
        Axios.spread((res, bahan) => {
          sortedByDateList(res.data.data);
          setDataItemBahan(bahan.data.data);
          setTotalPage(res.data.total_page);
          setDataCount(res.data.data_count);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_grup_aset: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? HargaSatuanJualApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : HargaSatuanJualApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // FUNCTION KONVERSI TANGGAL INPUT
  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(" ");
      const date = new Date(getDate[0]);
      return `${DateConvert(date).defaultDay}/${DateConvert(date).defaultMonth}/${
        DateConvert(date).defaultYear
      }`;
      // return DateConvert(date).detail
    }
    return "-";
  };

  // FUNCTION SORTIR LIST ITEM BAHAN BERDASARKAN TANGGAL TERBARU
  const sortedByDateList = (listItemBahan) => {
    const sorted = listItemBahan.sort(function (a, b) {
      return new Date(b.tgl_input) - new Date(a.tgl_input);
    });
    setData(sorted);
  };

  // FUNCTION CARI DATA DARI SERVER
  const onInputSearch = (text) => {
    const key = text.target.value; // SearchKey Value as key
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchKey(key)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true)
      }, 750)
    );
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_item_buaso: Yup.string().required("Pilih barang jadi"),
    harga_satuan_jual: Yup.string()
      .required("Masukkan Harga Satuan Jual")
      .typeError("Harga satuan jual tidak valid"),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    const [namaSatuan, setNamaSatuan] = useState("");

    // FORMIK INITIAL VALUES
    const formInitialValues = {
      id_item_buaso: "",
      satuan: "",
      harga_satuan_jual: "",
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      HargaSatuanJualApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Item Barang Jadi"
                  name="id_item_buaso"
                  placeholder="Pilih barang jadi"
                  onChange={(val) => {
                    setFieldValue("id_item_buaso", val.value);
                    setNamaSatuan(val.nama_satuan);
                  }}
                  option={dataItemBahan.map((val) => {
                    return {
                      value: val.id_item_buaso,
                      label: val.nama_item,
                      nama_satuan: val.nama_satuan,
                    };
                  })} //option harus berupa value dan label
                  defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                  error={errors.id_item_buaso && touched.id_item_buaso && true}
                  errorText={errors.id_item_buaso && touched.id_item_buaso && errors.id_item_buaso}
                />

                <Input
                  label="Satuan Jual"
                  type="text"
                  name="satuan"
                  placeholder="Satuan"
                  readOnly={true}
                  value={namaSatuan}
                  onChange={handleChange}
                  error={errors.satuan && touched.satuan && true}
                  errorText={errors.satuan}
                />

                {/* <Input
                  label="Harga Satuan Jual"
                  type="text"
                  name="harga_satuan_jual"
                  placeholder="Rp. "
                  style={{ textAlign: "right" }}
                  onChange={(event) => {
                    // GET INPUT VALUE
                    const { value } = event.target;
                    // GET ONLY NUMBER VALUE
                    const onlyNumber = value.replace(/[^\d]/g, "");
                    // SHOw CONVERT TO RUPIAH
                    event.target.value = RupiahConvert(onlyNumber).detail;
                    // set Formik value
                    values.harga_satuan_jual = onlyNumber;
                  }}
                  error={errors.harga_satuan_jual && touched.harga_satuan_jual && true}
                  errorText={errors.harga_satuan_jual}
                /> */}
                <InputCurrency
                  label="Harga Satuan Jual"
                  placeholder="Masukan harga kesepakatan"
                  value={parseFloat(values.harga_satuan_jual)}
                  onChange={(e) => {
                    setFieldValue("harga_satuan_jual", e);
                  }}
                  error={errors.harga_satuan_jual && touched.harga_satuan_jual && true}
                  errorText={errors.harga_satuan_jual}
                />
              </Modal.Body>

              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_item_buaso: updateData.id_item_buaso,
      harga_satuan_jual: updateData.harga_satuan_jual,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      // SEND UPDATED DATA TO SERVER
      HargaSatuanJualApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Item Barang Jadi"
                  name="id_item_buaso"
                  placeholder="Pilih barang jadi"
                  onChange={(val) => setFieldValue("id_item_buaso", val.value)}
                  isDisabled={true}
                  defaultValue={{
                    value: updateData.id_item_buaso,
                    label: updateData.nama_item,
                  }}
                  error={errors.id_item_buaso && touched.id_item_buaso && true}
                  errorText={errors.id_item_buaso && touched.id_item_buaso && errors.id_item_buaso}
                />

                <Input
                  label="Satuan Jual"
                  type="text"
                  name="satuan"
                  placeholder="Satuan Jual"
                  readOnly={true}
                  value={updateData.nama_satuan}
                  onChange={handleChange}
                  error={errors.satuan && touched.satuan && true}
                  errorText={errors.satuan}
                />

                {/* <Input
                  label="Harga Satuan Jual"
                  type="text"
                  value={RupiahConvert(values.harga_satuan_jual).detail}
                  style={{ textAlign: "right" }}
                  onChange={(event) => {
                    // GET INPUT VALUE
                    const { value } = event.target;
                    // GET ONLY NUMBER VALUE
                    const onlyNumber = value.replace(/[^\d]/g, "");
                    setFieldValue("harga_satuan_jual", onlyNumber);
                  }}
                  error={errors.harga_satuan_jual && touched.harga_satuan_jual && true}
                  errorText={errors.harga_satuan_jual}
                /> */}
                <InputCurrency
                  label="Harga Satuan Jual"
                  placeholder="Masukan harga kesepakatan"
                  value={parseFloat(values.harga_satuan_jual)}
                  onChange={(e) => {
                    setFieldValue("harga_satuan_jual", e);
                  }}
                  error={errors.harga_satuan_jual && touched.harga_satuan_jual && true}
                  errorText={errors.harga_satuan_jual}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_grup_aset: deleteData.id_grup_aset };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      HargaSatuanJualApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
      />
    );
  };

  const ViewModal = () => {
    // MENYIMPAN DATA HISTORY
    const [dataItemHistory, setDataItemHistory] = useState([]);
    const [historyPage, setHistoryPage] = useState(1);
    const [totalHistoryPage, setTotalHistoryPage] = useState(1);
    const [dataHistoryLength, setDataHistoryLength] = useState(10);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const [dataCountHistory, setDataCountHistory] = useState(0);

    const getDataHistoryItem = (id_item_buaso) => {
      HargaSatuanJualApi.getItemHistory(historyPage, dataHistoryLength, id_item_buaso)
        .then((res) => {
          console.log("dataHistory", res.data);
          setDataItemHistory(res.data.data);
          setTotalHistoryPage(res.data.total_page);
          setDataCountHistory(res.data.data_count);
        })
        .catch((err) => alert(err.response.data.message))
        .finally(() => setIsLoadingHistory(false));
    };

    useEffect(() => {
      setIsLoadingHistory(true);
      getDataHistoryItem(viewData.id_item_buaso);
    }, [historyPage, dataHistoryLength]);

    // KOMPONEN TABEL HISTORY
    const TableHistory = () => {
      return (
        <>
          <CRUDLayout.Table>
            <THead>
              <Tr>
                <ThFixed>No</ThFixed>
                <Th>Tanggal Update</Th>
                <Th>Harga Satuan Jual</Th>
              </Tr>
            </THead>
            <TBody>
              {dataItemHistory?.map((val, index) => {
                return (
                  <Tr key={val.id_harga_satuan_jual}>
                    <TdFixed>{PageNumber(historyPage, dataHistoryLength, index)}</TdFixed>
                    <TdFixed>{getConvertedDate(val.tgl_input)}</TdFixed>
                    <Td textRight>
                      {RupiahConvert(parseFloat(val.harga_satuan_jual).toString()).getWithComa}
                    </Td>
                  </Tr>
                );
              })}
            </TBody>
          </CRUDLayout.Table>
          <Pagination
            dataLength={dataHistoryLength}
            dataNumber={historyPage * dataHistoryLength - dataHistoryLength + 1}
            dataPage={historyPage * dataHistoryLength}
            dataCount={dataCountHistory}
            onDataLengthChange={(e) => {
              setPage(1);
              setDataHistoryLength(e.target.value);
            }}
            currentPage={historyPage}
            totalPage={totalHistoryPage}
            onPaginationChange={({ selected }) => setHistoryPage(selected + 1)}
          />
        </>
      );
    };

    return (
      <ReadModalLarge show={isViewData} onHide={() => setIsViewData(false)} title={title}>
        <Modal.Body>
          <div>
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="row mb-1">
                  <div className="col-md-2">
                    <h6 style={{ fontSize: "14px" }}>
                      <strong>Nama Item Bahan</strong>
                    </h6>
                  </div>
                  <div className="col-md-10">
                    <h6
                      className="text-capitalize"
                      style={{ fontSize: "14px" }}
                    >{`: ${viewData.nama_item}`}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mb-1">
                  <div className="col-md-2">
                    <h6 style={{ fontSize: "14px" }}>
                      <strong>Kode Item Bahan</strong>
                    </h6>
                  </div>
                  <div className="col-md-10">
                    <h6 style={{ fontSize: "14px" }}>{`: ${viewData.kode_item}`}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mb-1">
                  <div className="col-md-2">
                    <h6 style={{ fontSize: "14px" }}>
                      <strong>Satuan</strong>
                    </h6>
                  </div>
                  <div className="col-md-10">
                    <h6 style={{ fontSize: "14px" }}>{`: ${viewData.nama_satuan}`}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoadingHistory ? (
            <DataStatus loading={isLoadingHistory} text="Memuat Data" />
          ) : !Array.isArray(dataItemHistory) ? (
            <DataStatus text="Tidak dapat memuat data" />
          ) : dataItemHistory.length > 0 ? (
            <TableHistory />
          ) : (
            <>
              <hr />
              <DataStatus text="Tidak ada data histori" />
            </>
          )}
        </Modal.Body>
      </ReadModalLarge>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Item Bahan</ThFixed>
              <ThFixed>Tgl. Input</ThFixed>
              <Th>Nama Item Bahan</Th>
              <ThFixed>Satuan</ThFixed>
              <Th>Harga Satuan Jual</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ButtonGroup size="sm">
                        <ReadButton
                          onClick={() => {
                            setViewData(val);
                            setIsViewData(true);
                          }}
                        />
                        <UpdateButton
                          onClick={() => {
                            setUpdateData(val);
                            setIsUpdateForm(true);
                          }}
                        />
                      </ButtonGroup>
                      {/* <DeleteButton
                            onClick={() => {
                                setDeleteData(val);
                                setIsDeleteData(true);
                            }} />
                          <Switch
                              id={val.id_grup_aset}
                              checked={(val.is_hidden) ? false : true}
                              onChange={() => changeDataStatus(val.is_hidden, val.id_grup_aset)} /> */}
                    </div>
                  </TdFixed>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <TdFixed>{getConvertedDate(val.tgl_input)}</TdFixed>
                  <Td>{val.nama_item}</Td>
                  <TdFixed>{val.nama_satuan}</TdFixed>
                  <Td textRight>
                    {RupiahConvert(parseFloat(val.harga_satuan_jual).toString()).getWithComa}
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch}/>
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
      {isViewData && <ViewModal />}
    </CRUDLayout>
  );
};

export default HargaSatuanJual;

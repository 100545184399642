import Services from "../../../services";

class DashboardApi {
  async getTotalCustomer() {
    const data = await Services.get("/dashboard/customer");
    return data.data.data;
  }

  async getRankCustomer(params) {
    const data = await Services.get("/dashboard/peringkat_customer", {
      params,
    });
    return data.data;
  }

  async getTotal(params) {
    const data = await Services.get("/dashboard/total", { params });
    return data.data.data;
  }

  async getPembelianPerbulan(params) {
    const data = await Services.get("/dashboard/total_pembelian_perbulan", {
      params,
    });
    return data.data;
  }
  async getPembelianPerhari(params) {
    const data = await Services.get("/dashboard/total_pembelian_perhari", {
      params,
    });
    return data.data;
  }

  async getBarangSeringDiminta(params) {
    const data = await Services.get("/dashboard/barang", { params });
    return data.data;
  }

  async getNilaiPersediaan(params) {
    const data = await Services.get("/dashboard/nilai_persediaan", { params });
    return data.data;
  }
}

export default new DashboardApi();

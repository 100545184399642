import React from "react";

export const formInitialValues = (data, is_spk) => ({
  tgl_peluang: data?.tgl_peluang,
  no_peluang: data?.no_peluang,
  nama_peringkat_peluang: data?.nama_peringkat_peluang,
  nama_customer: data?.nama_customer,
  nama_proyek: data?.nama_proyek,
  nama_unit_produksi: data?.nama_unit_produksi,
  att: data?.att,
  tgl_sales_order: data?.tgl_sales_order ?? data?.tgl_sales_order_spk,
  no_sales_order: data?.no_sales_order ?? data?.no_sales_order_spk,
  batas_waktu: data?.batas_waktu,
  nama_sales: data?.nama_sales,
  keterangan_pengiriman: data?.keterangan_pengiriman,
  lokasi_pengiriman: data?.lokasi_pengiriman,
  lain_lain: data?.lain_lain,
  no_close_sales_order: data?.no_close_sales_order,
  tgl_close_sales_order: data?.tgl_close_sales_order,
  detail: data?.detail,
  is_spk: is_spk,
  id_sales_order: data?.id_sales_order ?? data?.id_sales_order_spk,
});

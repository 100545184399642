// HALAMAN YANG MEMUAT AKSI CREATE, UPDATE, READ RETUR PENJUALAN
import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Card, Modal, } from 'react-bootstrap'
import {
  CRUDLayout,
  Input,
  ActionButton,
  DataStatus,
  CreateModal,
  DeleteButton,
  Alert,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Td,
  Th,
  BackButton,
  CreateCustomerButton,
  TextArea,
  UpdateButton
} from '../../../components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useReactToPrint } from 'react-to-print'
import { ReturPenjualanApi } from '../../../api'
import { RupiahConvert, DateConvert } from '../../../utilities'
import PrintReturPenjualan from './PrintReturPenjualan'

const CRUReturPenjualan = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();

  // JIKA ACTION "UPDATE" MAKA id ADALAH id_retur_penjualan
  // JIKA ACTION "TAMBAH" MAKA id ADALAH id_faktur_penjualan
  // JIKA ACTION "DETAIL" MAKA id ADALAH id_faktur_penjualan
  let { id, action } = useParams();

  // GET STATE LOCATION UNTUK EDIT DATA
  const location = useLocation();
  // JIKA "UPDATE" MAKA SIMPAN no_retur_penjualan dan tgl_retur_penjualan KE DALAM VARIABLE
  let { no_retur_penjualan, tgl_retur_penjualan } = location?.state ?? 0;

  // STATE LOADING 
  const [isLoading, setIsLoading] = useState(true);

  // STATE MODAL 
  const [isReturModal, setIsReturModal] = useState(false);
  const [returData, setReturData] = useState({})

  // STATE MENAMPUNG DATA FAKTUR PENJUALAN DAN ITEM SALES ORDER
  const [data, setData] = useState([]);

  // STATE MENANMPUNG LIST ITEM SALES ORDER YANG DIRETUR
  const [dataListRetur, setDataListRetur] = useState([]);

  // STATE MENAMPUNG NO FAKTUR PENJUALAN
  const [noRetur, setNoRetur] = useState("");

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    // GET NO RETUR
    getNoRetur();

    // JIKA "TAMBAH" GET DETAIL FAKTUR PENJUALAN BY id_faktur_penjualan 
    action === 'tambah' ? ReturPenjualanApi.getDetailFaktur(id) //(paramaeter id adalah id_faktur_penjualan)
      .then((res) => {
        setData(res.data.data)
      })
      .finally(() => {
        setIsLoading(false)
      }) :
      // JIKA "UPDATE" GET RETUR PENJUALAN DETAIL BY id_retur_penjualan
      ReturPenjualanApi.getDetailRetur(id) //(paramaeter id adalah id_retur_penjualan)
        .then((res) => {
          console.log(res.data.data)
          setData(res.data.data)
        })
        .finally(() => {

          setIsLoading(false)
        })
  }

  // FUNCTION GET NO RETUR
  const getNoRetur = (date = new Date().toISOString().split('T')[0].split("-").join("/")) => {
    ReturPenjualanApi.getNomorReturPenjualan(date)
      .then((no_retur) => {
        setNoRetur(no_retur.data.data)
      })
  }

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(" ");
      const date = new Date(getDate[0]);
      return DateConvert(date).detail
    }
    return "-"
  }

  // FUNGSI INI KETIKA MENGHAPUS ITEM PADA list detail_retur, 
  // MAKA MENGCOPY value id_retur_penjualan_detail
  // KE ITEM BARANG DI LIST detail_so untuk kebutuhan mengubah qty_retur_penjualan
  const copyIdReturPenjualanDetail = (itemReturIndex, id_barang, id_gudang, id_retur_penjualan_detail) => {
    // MENCARI INDEX BARANG BERDASARKAN id_barang dan id_gudang PADA LIST detail_so
    let indexItem = data.detail_so.findIndex((e) => e.id_barang == id_barang && e.id_gudang == id_gudang)
    // TAMBAHKAN VALUE id_retur_penjualan_detail KE ITEM BARANG detail_so
    data.detail_so[indexItem].id_retur_penjualan_detail = id_retur_penjualan_detail

    let tempListRetur = dataListRetur.filter((item, itemIndex) => itemIndex !== itemReturIndex)
    setDataListRetur([...tempListRetur])
  }

  //FUNGSI UNTUK MENGECEK JIKA ITEM BARANG detail_so TERDAPAT PADA dataListRetur
  const isItemRetur = (item) => dataListRetur?.some(element => (element.id_barang == item.id_barang) && (element.id_gudang == item.id_gudang))

  const refPrint = useRef();
  // INIT USE REACT TO PRINT
  const reactToPrint = useReactToPrint({
    content: () => refPrint.current,
  });
  // ON CLICK HANDLE PRINT
  const handlePrint = () => {
    reactToPrint();
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    console.log(id, action)
    setIsLoading(true)

    // SET JUDUL NAVBAR
    setNavbarTitle(
      (action === "tambah")
        ? 'Tambah Data Retur Penjualan'
        : (action === "update")
          ? 'Update Retur Penjualan'
          : 'Detail Retur Penjualan');
    // GET DATA DARI SERVER
    getData();
  }, [])

  useEffect(() => {
    // JIKA  action "UPDATE/DETAIL" MAKA, MASUKKAN DATA LIST detail_retur ke DataListRetur, JIKA KOSONG ISI DENGAN ARRAY KOSONG
    action !== "tambah" ? setDataListRetur(data?.detail_retur ?? []) : setDataListRetur([])
  }, [data])

  // KOMPONEN MODAL TAMBAH RETUR BARANG (PILIH BARANG LALU INPUT JUMLAH ITEM YANG AKAN DI RETUR)
  const ReturModal = () => {
    const formReturInitial = {
      nama_barang: `${returData.kode_barang} | ${returData.nama_gudang} | ${returData.nama_barang}`,
      qty_retur_penjualan: parseInt(returData.qty_item)
    }

    const formReturValidation = Yup.object().shape({
      qty_retur_penjualan: Yup.number().required("Masukkan Jumlah Retur Barang")
    })

    // ON SUBMIT, TAMBAHKAN KE dataListRetur
    const returSubmitHandler = (values) => {
      // TRUE/FALSE
      let isHavingIdReturPenjualanDetail = 'id_retur_penjualan_detail' in returData

      setDataListRetur(prevData => [
        ...prevData,
        {
          ...returData,
          id_item_buaso: returData.id_barang,
          qty_retur_penjualan: values.qty_retur_penjualan,
          id_retur_penjualan_detail: isHavingIdReturPenjualanDetail ? returData.id_retur_penjualan_detail : null // CEK JIKA item_retur_penjualan_detail SUDAH ADA, JIKA BELUM ISI NULL
        }])
      setIsReturModal(false);
    }

    return (
      <CreateModal title="Tambah Data Retur" show={isReturModal} onHide={() => setIsReturModal(false)}>
        <Formik
          initialValues={formReturInitial}
          validationSchema={formReturValidation}
          onSubmit={returSubmitHandler}>
          {
            ({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Input
                    label="Nama Barang"
                    type="text"
                    name="nama_barang"
                    placeholder="Nama Barang"
                    readOnly={true}
                    value={values.nama_barang}
                    onChange={handleChange} />

                  <Input
                    label="Qty Retur"
                    type="text"
                    name="qty_retur_penjualan"
                    placeholder={parseInt(returData.qty_item)}
                    value={values.qty_retur_penjualan}
                    // onChange={handleChange}
                    onChange={(e) => {
                      let { value } = e.target
                      if (value > 0 && value <= parseInt(returData.qty_item)) {
                        values.qty_retur_penjualan = value;
                        setFieldValue("qty_retur_penjualan", value);
                      } else if (value > parseInt(returData.qty_item)) {
                        e.target.value = parseInt(returData.qty_item);
                        values.qty_retur_penjualan = value;
                        setFieldValue("qty_retur_penjualan", e.target.value);
                      } else {
                        setFieldValue("qty_retur_penjualan", "");
                      }
                    }
                    }
                    error={
                      errors.qty_retur_penjualan && touched.qty_retur_penjualan && true
                    }
                    errorText={errors.qty_retur_penjualan} />
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="primary"
                      text="Tambah"
                      className="mt-2 px-4"
                      loading={isSubmitting} />
                  </div>
                </Modal.Footer>
              </form>
            )}
        </Formik>
      </CreateModal >
    )
  }

  const Table = () => {
    // JIKA action "tambah" maka ambil list dari key api 'detail' 
    // JIKA action "update/detail" maka ambil list dari key api 'detail_so'
    let listBarang = (action === "tambah") ? data?.detail : data?.detail_so

    return (
      <>
        <small>List Barang dari Faktur Penjualan</small>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              {action === "detail" ? <></> : <ThFixed>Aksi</ThFixed>}
              <ThFixed>Kode Barang</ThFixed>
              <Th>Nama Barang</Th>
              <Th>Gudang</Th>
              <Th>Qty</Th>
              <Th>Satuan</Th>
              <Th>Harga Satuan</Th>
              <Th>Jumlah</Th>
            </Tr>
          </THead>
          <TBody>
            {
              !Array.isArray(listBarang) ? (<td colSpan="9"><DataStatus text="Tidak ada data" /></td>)
                : (listBarang.length > 0) ?
                  listBarang.map((val, index) => {
                    let isRetur = isItemRetur(val)
                    return (
                      <Tr key={index}>
                        <TdFixed >{index + 1}</TdFixed>
                        {action === "detail" ? <></> : <TdFixed>
                          <CreateCustomerButton
                            size="sm"
                            variant={isRetur ? "light" : "primary"}
                            tooltipText="Retur Barang"
                            onClick={() => {
                              setReturData(val)
                              isRetur ? setIsReturModal(false) : setIsReturModal(true)
                            }} />
                        </TdFixed>}
                        <TdFixed >{val.kode_barang}</TdFixed>
                        <Td>{val.nama_barang}</Td>
                        <Td>{val.nama_gudang}</Td>
                        <Td textRight>{parseInt(val.qty_item)}</Td>
                        <Td>{val.nama_satuan}</Td>
                        <Td textRight>{val.harga_satuan_jual ? RupiahConvert(String(val.harga_satuan_jual)).detail : 'Rp. 0'}</Td>
                        <Td textRight>{val.harga_satuan_jual && val.qty_item ? RupiahConvert(String(val.harga_satuan_jual * val.qty_item)).detail : 'Rp. 0'}</Td>
                      </Tr>
                    );
                  }) : <Tr>
                    <td colSpan="9">
                      <DataStatus text="Tidak ada data" />
                    </td>
                  </Tr>
            }
          </TBody>
        </CRUDLayout.Table>
      </>
    )
  }

  const TableReturBarang = () => {
    return (
      <>
        <small>List Barang Retur</small>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              {action === "detail" ? <></> : <ThFixed>Aksi</ThFixed>}
              <ThFixed>Kode Barang</ThFixed>
              <Th>Nama Barang</Th>
              <Th>Gudang</Th>
              <Th>Qty. Retur</Th>
              <Th>Satuan</Th>
              <Th>Harga Satuan</Th>
              <Th>Jumlah</Th>
            </Tr>
          </THead>
          <TBody>
            {
              (dataListRetur?.length > 0) ?
                dataListRetur?.map((val, index) => {
                  return (
                    <Tr key={index}>
                      <TdFixed >{index + 1}</TdFixed>
                      {action === "detail" ? <></> : <TdFixed>
                        <DeleteButton onClick={() => {

                          if (action == "tambah") {
                            // REMOVE SELECTED ITEM IN dataListRetur
                            let tempListRetur = dataListRetur.filter((item, itemIndex) => itemIndex !== index)
                            setDataListRetur([...tempListRetur])
                          } else {
                            // KETIKA DELETE BUTTON DI CLICK, MAKA AKAN MENGCOPY id_retur_penjualan_detail ke item BARANG
                            // YANG ADA DI LIST detail_so
                            copyIdReturPenjualanDetail(index, val.id_barang, val.id_gudang, val.id_retur_penjualan_detail);
                          }
                        }} />
                      </TdFixed>}
                      <TdFixed >{val.kode_barang}</TdFixed>
                      <Td >{val.nama_barang}</Td>
                      <Td >{val.nama_gudang}</Td>
                      <Td textRight>{parseInt(val.qty_retur_penjualan)}</Td>
                      <Td >{val.nama_satuan}</Td>
                      <Td textRight>{val.harga_satuan_jual ? RupiahConvert(String(val.harga_satuan_jual)).detail : 'Rp. 0'}</Td>
                      <Td textRight >{val.harga_satuan_jual && val.val.qty_retur_penjualan ? RupiahConvert(String(val.harga_satuan_jual * val.qty_retur_penjualan)).detail : 'Rp. 0'}</Td>
                    </Tr>
                  );
                }) : <Tr>
                  <td colSpan="9">
                    {(action === 'detail') ? <DataStatus text="Tidak ada barang retur penjualan" /> : <DataStatus text="Pilih barang dari faktur penjualan" />}
                  </td>
                </Tr>
            }
          </TBody>
        </CRUDLayout.Table>
      </>
    )
  }

  // INITIAL VALUES FORM TAMBAH DATA
  const formInitialValues = {
    tgl_retur_penjualan: tgl_retur_penjualan ?? new Date().toISOString().split('T')[0],
    no_retur_penjualan: noRetur,
    qty_retur: "",
    catatan_retur_penjualan: (action === "tambah") ? "" : data?.catatan_retur_penjualan ?? '-'
  }

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    tgl_retur_penjualan: Yup.date().required("Pilih Tanggal Retur")
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values, formik) => {
    const id_sales_order = data.id_sales_order;
    const tgl_faktur = data.tgl_faktur;

    // FORMAT DATA YANG DIKIRIM JIKA action "TAMBAH"
    const finalValuesTambah = {
      id_faktur_penjualan: id,
      id_sales_order,
      tgl_faktur,
      tgl_retur_penjualan: values.tgl_retur_penjualan,
      catatan_retur_penjualan: values.catatan_retur_penjualan,
      detail: dataListRetur
    }

    // FORMAT DATA YANG DIKIRIM JIKA action "UPDATE"
    const finalValuesUpdate = {
      id_retur_penjualan: id,
      tgl_retur_penjualan: values.tgl_retur_penjualan,
      catatan_retur_penjualan: values.catatan_retur_penjualan,
      detail_retur: dataListRetur
    }

    console.log("finalValuesTambah:", finalValuesTambah)
    console.log("finalValuesUpdate:", finalValuesUpdate)

    switch (action) {
      case "tambah":
        ReturPenjualanApi.createReturPenjualan(finalValuesTambah).then((res) => {
          history.push('/sls/transaksi/retur-penjualan',
            {
              registerAlertConfig: {
                variant: "primary",
                text: "Tambah data berhasil!",
              }
            })
        }).catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`
          });
        }).finally(() => {
          formik.setSubmitting(false);
          // SHOW ALERT
          setShowAlert(true);
        })
        break;

      default:
        // SEND UPDATED DATA RETUR PENJUALAN TO SERVER
        ReturPenjualanApi.updateReturPenjualan(finalValuesUpdate).then((res) => {
          console.log(res)
          history.push('/sls/transaksi/retur-penjualan',
            {
              registerAlertConfig: {
                variant: "primary",
                text: "Update data berhasil!",
              }
            })
        }).catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`
          });
        }).finally(() => {
          formik.setSubmitting(false);
          // SHOW ALERT
          setShowAlert(true);
        })
        break;
    }
  }

  return (
    <>
      <CRUDLayout>
        {/* Alert */}
        <Alert show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)} />

        <Card className="pb-3 mt-3">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>{(action === "tambah") ? "Tambah Retur Penjualan" : (action === "update") ? `Update Retur Penjualan / ${no_retur_penjualan}` : `Detail Retur Penjualan / ${no_retur_penjualan}`}</b>
            <BackButton size="sm" onClick={() => history.goBack()} />
          </Card.Header>
          {
            isLoading ? (<DataStatus loading={isLoading} text="Memuat Data" />) :
              (
                <Card.Body>
                  <Formik
                    initialValues={formInitialValues}
                    validationSchema={formValidationSchema}
                    onSubmit={formSubmitHandler}>
                    {
                      ({ values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldValue, setSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                          <div className='container my-2 px-5'>
                            <div className='row mb-3'>
                              <div className="col-md-3 col-sm-12">
                                <Input
                                  label="Tgl. Retur Penjualan"
                                  type="date"
                                  name="tgl_retur_penjualan"
                                  value={values.tgl_retur_penjualan}
                                  readOnly={(action === "detail") ? true : false}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const date = value.split("-");
                                    getNoRetur(date.join("/"));
                                    setFieldValue("tgl_retur_penjualan", value);
                                  }}
                                  error={errors.tgl_retur_penjualan && touched.tgl_retur_penjualan && true}
                                  errorText={errors.tgl_retur_penjualan} />
                              </div>

                              <div className="col-md-3 col-sm-12">
                                <Input
                                  label="No. Retur Penjualan"
                                  type="text"
                                  style={{ textTransform: "uppercase" }}
                                  name="no_retur_penjualan"
                                  value={no_retur_penjualan ?? noRetur}
                                  readOnly={true} />
                              </div>
                            </div>

                            <div className='row'>
                              <div className="col">
                                <div className='row'>
                                  <div className="col">
                                    <h6>Tgl. Sales Order</h6>
                                  </div>
                                  <div className="col">
                                    <h6>: {getConvertedDate(data?.tgl_sales_order)}</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className='row'>
                                  <div className="col">
                                    <h6>No. Sales Order</h6>
                                  </div>
                                  <div className="col">
                                    <h6 className="text-uppercase">: {data?.no_sales_order}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='row'>
                              <div className="col">
                                <div className='row'>
                                  <div className="col">
                                    <h6>Tgl. Faktur Penjualan</h6>
                                  </div>
                                  <div className="col">
                                    <h6>: {getConvertedDate(data?.tgl_faktur)}</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div className='row'>
                                  <div className="col">
                                    <h6>No. Faktur Penjualan</h6>
                                  </div>
                                  <div className="col">
                                    <h6 className="text-uppercase">: {data?.no_faktur}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="my-3">
                              <div className="row">
                                <div className="col">
                                  <h6>Nama Customer</h6>
                                </div>
                                <div className="col-9">
                                  <h6>: {data.nama_customer}</h6>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col">
                                  <h6>Nama Sales</h6>
                                </div>
                                <div className="col-9">
                                  <h6>: {data?.nama_karyawan ?? '-'}</h6>
                                </div>
                              </div>
                            </div>

                            <Table />
                            <TableReturBarang />
                            <TextArea
                              label="Catatan Retur Penjualan"
                              placeholder="Masukkan catatan retur penjualan"
                              name="catatan_retur_penjualan"
                              value={values.catatan_retur_penjualan}
                              onChange={handleChange}
                              isDisabled={(action == "detail") ? true : false}
                              rows={3}
                            />
                            <div className="d-flex justify-content-end">
                              {(action !== "detail")
                                ? (<ActionButton
                                  type="submit"
                                  variant={(action === "tambah") ? "primary" : "success"}
                                  text={(action === "tambah") ? "Tambah" : "Update"}
                                  className="mt-2 px-4"
                                  loading={isSubmitting} />)
                                : (<ActionButton
                                  className="btn btn-primary text-white mt-2"
                                  size="sm"
                                  text="Cetak Retur Penjualan"
                                  onClick={handlePrint} />)}
                            </div>
                          </div >
                        </form>
                      )
                    }
                  </Formik>
                </Card.Body>
              )
          }
        </Card>
        <div style={{ display: 'none' }}>
          <PrintReturPenjualan id={id} dataPrint={data} ref={refPrint} />
        </div>
      </CRUDLayout>
      {isReturModal && <ReturModal />}
    </>
  );
}
export default CRUReturPenjualan

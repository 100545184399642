import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, ButtonGroup } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import _ from "lodash";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  Tr,
  ThFixed,
  TdFixed,
  THead,
  TBody,
  CreateButton,
  FilterButton,
  ApprovalStatusButton,
} from "components";
import { DateConvert, DecimalConvert, TableNumber } from "utilities";
import { FakturPenjualanApi } from "api";
import { ModalFilter } from "./Section";

const ListFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataFakturPenjualan, setDataFakturPenjualan] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.faktur?.filter?.active,
      tgl_faktur_mulai: location?.state?.faktur?.filter?.tgl_faktur_mulai,
      tgl_faktur_selesai: location?.state?.faktur?.filter?.tgl_faktur_selesai,
      tgl_sales_order_mulai:
        location?.state?.faktur?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai:
        location?.state?.faktur?.filter?.tgl_sales_order_selesai,
      customer: location?.state?.faktur?.filter?.customer,
      sales: location?.state?.faktur?.filter?.sales,
      status_approval: location?.state?.faktur?.filter?.status_approval,
      item_sj: location?.state?.faktur?.filter?.item_sj,
    },
    pagination: {
      page: location?.state?.faktur?.filter?.page ?? "1",
      dataLength: location?.state?.faktur?.filter?.dataLength ?? "10",
      totalPage: location?.state?.faktur?.filter?.totalPage ?? "1",
      dataCount: location?.state?.faktur?.filter?.dataCount ?? "0",
    },
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataDummy, setDataDummy] = useState([
    {
      id_faktur_penjualan: 1,
      tgl_faktur: "29/10/2010",
      tgl_sales_order: "11/10/2000",
      no_faktur: "2022/MKT/TJS/22/01",
      no_sales_order: "2021/TJS/MKT/11/09",
      nama_customer: "I Wayan Adit",
      nama_sales: "Nengah Suendra",
      batas_waktu: "01/01/2011",
      status_approval: "APP",
    },
    {
      id_faktur_penjualan: 2,
      tgl_faktur: "29/10/2010",
      tgl_sales_order: "11/10/2000",
      no_faktur: "2022/MKT/TJS/22/01",
      no_sales_order: "2021/TJS/MKT/11/09",
      nama_customer: "I Wayan Adit",
      nama_sales: "Nengah Suendra",
      batas_waktu: "01/01/2011",
      status_approval: "REV",
    },
  ]);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const [showAlert, setShowAlert] = useState(false);
  const [searchTime, setSearchTime] = useState(null)

  const getInitialData = () => {
    setIsPageLoading(true);

    FakturPenjualanApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_faktur_mulai: dataFilter?.filter?.tgl_faktur_mulai,
      tgl_faktur_selesai: dataFilter?.filter?.tgl_faktur_selesai,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      customer: dataFilter?.filter?.customer,
      sales: dataFilter?.filter?.sales,
      item_sj: dataFilter?.filter?.item_sj,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then((res) => {
        setDataFakturPenjualan(res?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
        setShowAlert(true);
      },750)
    )
  };

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase();
    if (newStatus === "REJ") {
      return {
        variant: "outline-danger",
        label: "REJECT",
      };
    }

    if (newStatus === "REV") {
      return {
        variant: "outline-warning",
        label: "REVISI",
      };
    }

    if (newStatus === "APP") {
      return {
        variant: "outline-success",
        label: "APPROVED",
      };
    }

    if (newStatus === "VER") {
      return {
        variant: "outline-success",
        label: "VERIFIED",
      };
    }

    return {
      variant: "outline-secondary",
      label: "PENDING",
    };
  };

  useEffect(() => {
    setNavbarTitle("Faktur Penjualan");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_faktur_mulai,
    dataFilter?.filter?.tgl_faktur_selesai,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.sales,
    dataFilter?.filter?.item_sj,
    dataFilter?.filter?.status_approval,
  ]);

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        };
      // return "APPROVED";
      if (convert === "VER")
        return {
          variant: "outline-success",
          label: "VERIFIED",
        };
      // return "VERIFIED";
      if (convert === "REV")
        return {
          variant: "outline-warning",
          label: "REVISI",
        };
      // return "REVISI";
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECT",
        };
      // return "REJECT";

      return {
        variant: "outline-secondary",
        label: "PENDING",
      };
      // return "PENDING";
    };
    const SuratJalanCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((val, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {`${val.no_surat_jalan ?? "-"} | ${val.nama_item ?? "-"} ${
                      DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa
                    } ${val?.kode_satuan}`}
                  </li>
                );
              } else {
                return (
                  index <= 2 && (
                    <li key={index} index={index}>
                      {`${val.no_surat_jalan ?? "-"} | ${
                        val.nama_item ?? "-"
                      } ${
                        DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa
                      } ${val?.kode_satuan}`}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length <= 3 ? (
            ""
          ) : (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => {
                setisReadMoreClick((prev) => !prev);
              }}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };
    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Faktur Penjualan</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Faktur Penjualan</ThFixed>
              <Th>Informasi Sales Order</Th>
              <Th>Informasi Surat Jalan</Th>
              <Th>Customer</Th>
              <Th>Sales</Th>
              <ThFixed>Tgl. Batas Waktu</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataFakturPenjualan.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <Td className="d-flex">
                  <ButtonGroup size="sm">
                    <ReadButton
                      onClick={() =>
                        history.push(
                          "/sls/transaksi/faktur-penjualan/detail/" +
                            val.id_faktur_penjualan
                        )
                      }
                    />
                    {(val.status_approval === "REV" ||
                      !val.status_approval) && (
                      <UpdateButton
                        onClick={() =>
                          history.push(
                            "/sls/transaksi/faktur-penjualan/ubah/" +
                              val.id_faktur_penjualan
                          )
                        }
                      />
                    )}
                  </ButtonGroup>
                </Td>
                <TdFixed>
                  <div>
                    {val.tgl_faktur
                      ? DateConvert(new Date(val.tgl_faktur)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val.no_faktur ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_sales_order
                      ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val.no_sales_order ?? "-"}</div>
                </TdFixed>
                <Td className="text-nowrap">
                  <SuratJalanCollapse data={val?.surat_jalan ?? []} />
                </Td>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_sales ?? "-"}</Td>
                <TdFixed>
                  {val.batas_waktu
                    ? DateConvert(new Date(val.batas_waktu)).defaultDMY
                    : "-"}
                </TdFixed>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={
                      generateStatusApproval(val.status_approval).variant
                    }
                  >
                    {generateStatusApproval(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!dataFakturPenjualan || dataFakturPenjualan.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch}/>
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push("/sls/transaksi/faktur-penjualan/sales-order", {
                ...location.state,
                faktur: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default ListFakturPenjualan;

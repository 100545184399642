import { memo, useEffect, useState } from "react";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { HiOutlineUser } from "react-icons/hi";
import { Button, ChartBarVertical, ChartLine, Select } from "components2";
import { Formik } from "formik";
import { range } from "lodash";
import { useQuery } from "react-query";

import { DatePicker } from "components";
import { dateConvert, rupiahConvert } from "utilities2";
import DashboardApi from "./__DashboardApi__/";

const date = dateConvert();
const rupiah = rupiahConvert();
const yearNow = date.getYear(new Date());

const yearOptions = range(2000, parseInt(yearNow + 1))
  .map((val) => ({
    label: val,
    value: val,
  }))
  .reverse();

const months = [
  { label: "Januari", value: "01" },
  { label: "Februari", value: "02" },
  { label: "Maret", value: "03" },
  { label: "April", value: "04" },
  { label: "Mei", value: "05" },
  { label: "Juni", value: "06" },
  { label: "Juli", value: "07" },
  { label: "Agustus", value: "08" },
  { label: "September", value: "09" },
  { label: "Oktober", value: "10" },
  { label: "November", value: "11" },
  { label: "Desember", value: "12" },
];

const InfoItem = memo(({ icon, title, value, loading }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm p-3"
      style={{ height: "100%" }}
    >
      {icon ? (
        icon
      ) : (
        <div
          className="bg-primary rounded px-2 my-2 mr-2"
          style={{ fontSize: 30 }}
        >
          Rp
        </div>
      )}

      <div className="d-flex flex-column align-items-end justify-content-end">
        <div
          className="text-nowrap mb-1"
          style={{ fontSize: 18, fontWeight: 400 }}
        >
          {title}
        </div>
        {loading ? (
          <small>Memuat data . . .</small>
        ) : (
          <b
            className="ml-2"
            style={{ fontSize: 20, fontWeight: "bold", lineHeight: 1.2 }}
          >
            {value}
          </b>
        )}
      </div>
    </div>
  );
});

const InfoRankItem = memo(({ value, loading, startMonth, endMonth, year }) => {
  return (
    <div className="bg-white rounded border shadow-sm px-4 py-2 mb-3">
      <div className="text-center text-nowrap">
        Customer Terbaik {date.getDetailMonth(startMonth)} s/d{" "}
        {date.getDetailMonth(endMonth)} {year}
      </div>
      <div>
        {loading ? (
          <small>Memuat data . . .</small>
        ) : (
          value?.data?.map((val) => (
            <tr>
              <td>
                <div className="bg-warning rounded px-2 my-2 mr-3">
                  {val?.peringkat ?? "-"}
                </div>
              </td>
              <td>
                <small>{val?.nama_customer ?? "-"}</small>
              </td>
            </tr>
          ))
        )}
      </div>
    </div>
  );
});

const generateMonthInRange = (startMonth, endMonth) => {
  const awal = new Date(startMonth).getMonth() + 1;
  const akhir = new Date(endMonth).getMonth() + 1;

  const monthOptions = months.filter(
    ({ value }) => value <= akhir && value >= awal
  );

  return monthOptions;
};

const Dashboard = ({ setNavbarTitle }) => {
  const TODAY = new Date();

  const PAST_MONTH = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth() - 1,
    TODAY.getDate()
  );

  const [filter, setFilter] = useState({
    startMonth: PAST_MONTH,
    endMonth: TODAY,
    year: date.getYear(TODAY),
    monthOptions: generateMonthInRange(PAST_MONTH, TODAY),
  });

  const dateSelected = {
    tahun: filter.year,
    bulan_awal: parseInt(date.getMonth(filter.startMonth)),
    bulan_akhir: parseInt(date.getMonth(filter.endMonth)),
  };

  const ChartBarPerMonth = ({ bulanClick }) => {
    let awal =
      filter.startMonth === undefined
        ? undefined
        : new Date(filter.startMonth).getMonth() + 1;
    let akhir =
      filter.endMonth === undefined
        ? undefined
        : new Date(filter.endMonth).getMonth() + 1;
    let tahun = filter.year;

    if (awal === undefined) {
      awal = new Date().getMonth() + 1;
    }
    if (akhir === undefined) {
      akhir = new Date().getMonth() + 1;
    }
    if (tahun === undefined) {
      tahun = new Date().getFullYear();
    }

    const getTotalPembelianPerhari = useQuery(
      ["dashboard", "pembelianPerhari", awal, tahun, akhir],
      () =>
        DashboardApi.getPembelianPerhari({
          tahun: tahun,
          bulan_awal: awal,
          bulan_akhir: akhir,
        })
    );

    const number = Number(bulanClick);
    const baru = number.toString();

    return (
      <>
        <div className="text-center">
          <b>
            Total Penjualan Per Hari Bulan{" "}
            {months.find((val) => val.value === bulanClick).label}
          </b>
        </div>
        {getTotalPembelianPerhari?.isFetching ? (
          <></>
        ) : (
          <>
            <ChartBarVertical
              showLegend={false}
              data={{
                labels:
                  getTotalPembelianPerhari?.data?.data[baru]?.tanggal_pembelian,
                datasets: [
                  {
                    data: getTotalPembelianPerhari?.data?.data[baru]?.total,
                  },
                ],
              }}
              options={{
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (val) => rupiah.getWithComa(val.raw),
                    },
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: (val) => rupiah.getWithComaWithoutLabel(val),
                    },
                  },
                },
              }}
            />
          </>
        )}
      </>
    );
  };

  const getTotalCustomer = useQuery(["dashboard", "totalCustomer"], () =>
    DashboardApi.getTotalCustomer()
  );

  const getRankingCustomer = useQuery(
    ["dashboard", "rankingCustomer", dateSelected, filter],
    () => DashboardApi.getRankCustomer(dateSelected)
  );

  const getTotal = useQuery(["dashboard", "total", dateSelected, filter], () =>
    DashboardApi.getTotal(dateSelected)
  );

  const getTotalPembelianPerbualn = useQuery(
    ["dashboard", "pembelianPerbulan", dateSelected, filter],
    () => DashboardApi.getPembelianPerbulan({ tahun: dateSelected?.tahun })
  );

  useEffect(() => {
    setNavbarTitle("Dashboard");
  }, []);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          startMonth: filter.startMonth,
          endMonth: filter.endMonth,
          year: filter.year,
        }}
        onSubmit={(val) => {
          setFilter({
            startMonth: val.startMonth,
            endMonth: val.endMonth,
            year: val.year,
            monthOptions: generateMonthInRange(val.startMonth, val.endMonth),
          });
        }}
      >
        {({ values, setFieldValue, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              <div
                className="d-flex align-items-center mr-2"
                style={{ marginBottom: 30 }}
              >
                <span className="mr-2">Bulan</span>
                <div style={{ width: 150 }}>
                  <DatePicker
                    selectsRange
                    showMonthYearPicker
                    dateFormat="MMMM"
                    startDate={
                      values.startMonth ? new Date(values.startMonth) : ""
                    }
                    endDate={values.endMonth ? new Date(values.endMonth) : ""}
                    onChange={(dates) => {
                      const [start, end] = dates;

                      setValues({
                        ...values,
                        startMonth: start,
                        endMonth: end,
                      });
                    }}
                  />
                </div>
              </div>

              {/* Tahun */}
              <div
                className="d-flex align-items-center mr-2"
                style={{ marginBottom: 40 }}
              >
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={yearOptions}
                    defaultValue={yearOptions.find(
                      (val) => val.value.toString() === values.year.toString()
                    )}
                    onChange={(val) => {
                      setFieldValue("year", val.value);
                    }}
                  />
                </div>
              </div>

              {/* Button */}
              <div style={{ marginTop: 5 }}>
                <Button
                  disabled={!values.startMonth || !values.endMonth}
                  text="Filter"
                  className="px-4"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="">
        <Col className="mb-3">
          <InfoItem
            loading={getTotalCustomer?.isFetching}
            title="Total Customer"
            icon={<HiOutlineUser fontSize={50} />}
            value={getTotalCustomer?.data?.total_customer ?? "-"}
          />
        </Col>

        {/* Info Total Sales Order */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotal?.isFetching}
            title="Total Sales Order"
            value={rupiah.getWithComa(getTotal?.data?.total_so ?? 0)}
          />
        </Col>

        {/* Info Total SOSPK */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotal?.isFetching}
            title="Total Sales Order SPK"
            value={rupiah.getWithComa(getTotal?.data?.total_sospk ?? 0)}
          />
        </Col>

        {/* Info Total Penjualan */}
        <Col className="mb-3">
          <InfoItem
            loading={getTotal?.isFetching}
            title="Total Penjualan"
            value={rupiah.getWithComa(getTotal?.data?.total_penjualan ?? 0)}
          />
        </Col>

        {/* Info Ranking Customer*/}
        {/* <Col className="mb-3"></Col> */}
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6} className="my-3">
          <InfoRankItem
            loading={getRankingCustomer?.isFetching}
            startMonth={filter.startMonth}
            endMonth={filter.endMonth}
            year={filter.year}
            value={getRankingCustomer?.data ?? []}
          />

          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: "100%" }}
          >
            <div className="text-center">
              <b>Total Penjualan Per Bulan Tahun {dateSelected.tahun}</b>
            </div>
            {getTotalPembelianPerbualn.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartLine
                  showLegend={false}
                  data={{
                    labels: months.map((val) => val.label),
                    datasets: [
                      {
                        data: months.map((val) => {
                          const findMonth =
                            getTotalPembelianPerbualn?.data?.data?.find(
                              (el) =>
                                el?.bulan_pembelian?.toString() ===
                                val.value.toString()
                            );

                          return findMonth?.total
                            ? parseFloat(findMonth.total)
                            : 0;
                        }),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>

        <Col md={6} className="my-3">
          <Card>
            <Card.Body>
              <Tabs
                key={filter?.monthOptions[0]?.value}
                defaultActiveKey={filter?.monthOptions[0]?.value}
                className="mb-3"
              >
                {filter.monthOptions.map((val) => (
                  <Tab title={val.label.slice(0, 3)} eventKey={val.value}>
                    <ChartBarPerMonth
                      data={getTotalPembelianPerbualn?.data?.data}
                      dateValue={`${val.value}-01-${dateSelected.tahun}`}
                      year={dateSelected.tahun}
                      bulanAwal={dateSelected.bulan_awal}
                      bulanAkhir={dateSelected.bulan_akhir}
                      bulanClick={`${val.value}`}
                    />
                  </Tab>
                ))}
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;

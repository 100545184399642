import { Row, Col } from "react-bootstrap";
import { DateConvert } from "utilities";

const InfoSection = ({ dataInfo }) => {
  const InfoItem = ({ title, value }) => (
    <tr>
      <td width={125}>{title}</td>
      <td className="pr-2">:</td>
      <td>{value}</td>
    </tr>
  );

  const InfoItemVertical = ({ title, value }) => (
    <div>
      <small style={{ fontSize: 12 }} className="text-secondary">
        {title}
      </small>
      <div style={{ fontSize: 14 }}>{value}</div>
    </div>
  );

  return (
    <>
      <Row>
        <Col md={6} sm={6} xs={6}>
          <table style={{ fontSize: 14 }}>
            <tbody>
              <InfoItem
                title="Tgl. Sales Order"
                value={
                  dataInfo.tgl_sales_order
                    ? DateConvert(new Date(dataInfo.tgl_sales_order)).detail
                    : "-"
                }
              />
              <InfoItem
                title="No. Sales Order"
                value={dataInfo.no_sales_order}
              />
            </tbody>
          </table>
        </Col>
        <Col md={6} sm={6} xs={6}>
          <table style={{ fontSize: 14 }}>
            <tbody>
              <InfoItem title="Customer" value={dataInfo.customer} />
              <InfoItem title="Sales" value={dataInfo.sales} />
              <InfoItem title="Proyek" value={dataInfo.nama_proyek} />
              <InfoItem title="Unit Produksi" value={dataInfo.nama_unit_produksi} />
            </tbody>
          </table>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6} sm={6} xs={6}>
          <InfoItemVertical
            title="Tgl. Faktur Penjualan"
            value={
              dataInfo.tgl_faktur_penjualan
                ? DateConvert(new Date(dataInfo.tgl_faktur_penjualan)).detail
                : "-"
            }
          />
        </Col>
        <Col md={6} sm={6} xs={6}>
          <InfoItemVertical
            title="No. Faktur Penjualan"
            value={dataInfo.no_faktur_penjualan}
          />
        </Col>
      </Row>
    </>
  );
};

export default InfoSection;

import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { FakturPenjualanApi } from "api";
// import { RegistrasiPeluangApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataCustomer, setDataCustomer] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [dataSales, setDataSales] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [dataItemSuratJalan, setDataItemSuratJulan] = useState([
    { label: "Semua", value: undefined },
  ]);
  const [dataStatusApproval, setDataStatusApproval] = useState([
    {
      label: "Semua",
      value: undefined,
    },
    {
      label: "APPROVED",
      value: "APP",
    },
    {
      label: "VERIFIED",
      value: "VER",
    },
    {
      label: "REVISI",
      value: "REV",
    },
    {
      label: "REJECT",
      value: "REJ",
    },
    {
      label: "PENDING",
      value: "PEN",
    },
  ]);
  console.log(dataSales);
  const [loading, setLoading] = useState(true);
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([
      FakturPenjualanApi.getDropdownCustomer(),
      FakturPenjualanApi.getDropdownSales(),
      FakturPenjualanApi.getItemSuratJalan(),
    ])
      .then(
        Axios.spread((customer, sales, itemSj) => {
          const mapDataCustomer = customer?.data?.data
            ? customer.data.data.map((val) => ({
                label: val?.nama_customer,
                value: val?.id_customer,
              }))
            : [];
          const mapDataSales = sales?.data?.data
            ? sales.data.data.map((val) => ({
                label: val?.nama_karyawan,
                value: val?.id_karyawan,
              }))
            : [];

          const mapDataSj = itemSj?.data?.data
            ? itemSj.data.data.map((val) => ({
                label: val?.nama_item,
                value: val?.id_item_buaso,
              }))
            : [];

          setDataCustomer([...dataCustomer, ...mapDataCustomer]);
          setDataSales([...dataSales, ...mapDataSales]);
          setDataItemSuratJulan([...dataItemSuratJalan, ...mapDataSj]);
        })
      )
      .finally(() => setLoading(false));
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_faktur_mulai: data?.filter?.tgl_faktur_mulai,
    tgl_faktur_selesai: data?.filter?.tgl_faktur_selesai,
    tgl_sales_order_mulai: data?.filter?.tgl_sales_order_mulai,
    tgl_sales_order_selesai: data?.filter?.tgl_sales_order_selesai,
    customer: data?.filter?.customer,
    sales: data?.filter?.sales,
    item_sj: data?.filter?.item_sj,
    status_approval: data?.filter?.status_approval,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalFakturChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_faktur_mulai: startDate,
      tgl_faktur_selesai: endDate,
    });
  };
  const onTanggalSOChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_sales_order_mulai: startDate,
      tgl_sales_order_selesai: endDate,
    });
  };
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_faktur_mulai: undefined,
      tgl_faktur_selesai: undefined,
      tgl_sales_order_mulai: undefined,
      tgl_sales_order_selesai: undefined,
      customer: undefined,
      sales: undefined,
      item_sj: undefined,
      status_approval: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}>
          <DatePicker
            selectsRange
            label="Tgl. Faktur Penjualan"
            placeholderText="Pilih Tanggal Faktur Penjualan"
            startDate={
              values.tgl_faktur_mulai ? new Date(values.tgl_faktur_mulai) : ""
            }
            endDate={
              values.tgl_faktur_selesai
                ? new Date(values.tgl_faktur_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalFakturChange(dates, values, setValues)
            }
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label="Tgl. Sales Order"
            placeholderText="Pilih Tanggal Sales Order"
            startDate={
              values.tgl_sales_order_mulai
                ? new Date(values.tgl_sales_order_mulai)
                : ""
            }
            endDate={
              values.tgl_sales_order_selesai
                ? new Date(values.tgl_sales_order_selesai)
                : ""
            }
            onChange={(dates) => onTanggalSOChange(dates, values, setValues)}
            monthsShown={2}
          />

          <SelectSearch
            key={values.item_sj}
            label="Item Surat Jalan"
            placeholder="Pilih item surat jallan"
            defaultValue={dataItemSuratJalan.find(
              (val) => val.value === values.item_sj
            )}
            option={dataItemSuratJalan}
            onChange={(val) => setFieldValue("item_sj", val.value)}
            loading={loading}
          />

          <SelectSearch
            key={values.customer}
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dataCustomer.find(
              (val) => val.value === values.customer
            )}
            option={dataCustomer}
            onChange={(val) => setFieldValue("customer", val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.sales}
            label="Sales"
            placeholder="Pilih Sales"
            defaultValue={dataSales.find((val) => val.value === values.sales)}
            option={dataSales}
            onChange={(val) => setFieldValue("sales", val.value)}
            loading={loading}
          />

          <SelectSearch
            key={values.status_approval}
            label="Status Approval"
            placeholder="Pilih Status Approval"
            defaultValue={dataStatusApproval.find(
              (val) => val.value === values.status_approval
            )}
            option={dataStatusApproval}
            onChange={(val) => setFieldValue("status_approval", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;

// React
import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

// Section
import { InfoPeluangSection, InfoRABSection, FormSection, ListBarangJadiSection } from "./Section";

// Component
import { DataStatus, BackButton, Alert } from "components";
import { Card, Row, Col } from "react-bootstrap";
import { DateConvert } from "utilities";

// Form
import { Formik } from "formik";
import { TransaksiSalesOrderApi } from "api";

const DetailSalesOrder = ({ setNavbarTitle }) => {
  // Variables
  const { id_sales_order } = useParams();
  const title = "Sales Order";
  const history = useHistory();
  const location = useLocation();

  // States
  const [page, setPage] = useState({
    loading: true,
    status: false,
  });
  const [data, setData] = useState({});
  const [dataDetail, setDataDetail] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "",
    text: "",
  });

  useEffect(() => {
    setNavbarTitle(title);

    TransaksiSalesOrderApi.single({
      id_sales_order,
      id_unit_produksi: sessionStorage.getItem("id_unit_produksi"),
    })
      .then((res) => {
        const value = res.data.data;
        setData(value);

        const detail = value?.detail ?? [];
        let detailBaru = [];
        if (detail.length > 0) {
          detail.map((val) =>
            detailBaru.push({
              ...val,
              gudang: val.gudang ?? [],
              kode_item: val.kode_barang ?? "-",
              nama_item: val.nama_barang ?? "-",
              kode_satuan_pakai: val.kode_satuan ?? "-",
              qty_item: val.qty_sales_order ? parseFloat(val.qty_sales_order).toPrecision() : 0,
              unit_cost: val.unit_cost ? parseFloat(val.unit_cost) : 0,
              harga_satuan_jual: val.unit_cost ? parseFloat(val.unit_cost) : 0,
            })
          );
        }
        setDataDetail(detailBaru);

        setPage({
          loading: false,
          status: true,
        });
      })
      .catch(() =>
        setPage({
          loading: false,
          status: false,
        })
      );

    return () =>
      setPage({
        loading: true,
        status: true,
      });

    // eslint-disable-next-line
  }, []);

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Sales Order SPK</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {data?.stakeholder?.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval === "VER" || val.status_approval === "REV"
                      ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                      : val.status_approval === "APP"
                      ? "Pengesah"
                      : val.status_approval === "PEN"
                      ? "Pembuat"
                      : "Di Tolak Oleh"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const initialValues = {
    tgl_sales_order: data?.tgl_sales_order
      ? DateConvert(new Date(data.tgl_sales_order)).default
      : "",
    no_sales_order: data?.no_sales_order ?? "",
    id_rab: data?.id_rab ?? "",
    batas_waktu: data?.batas_waktu ? DateConvert(new Date(data.batas_waktu)).default : "",
    id_karyawan: data?.id_sales ?? null,
    diskon: data?.diskon ? parseFloat(data.diskon) : 0,
    ppn: data?.ppn ? parseFloat(data.ppn) : 0,
    catatan: data?.catatan ?? "",
    alamat_pengiriman: data?.alamat_pengiriman ?? "",
    id_customer: data?.id_customer ?? "",
  };

  if (page.loading) {
    return <DataStatus loading={true} text="Memuat Data..." />;
  } else if (!page.status) {
    return <DataStatus text="Data Gagal Dimuat" />;
  } else {
    return (
      <>
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />

        <div className="d-flex justify-content-between align-items-end mb-2">
          <h6 className="font-weight-bold">Detail Data {title}</h6>
          <BackButton
            size="sm"
            onClick={() => history.push("/sls/transaksi/sales-order", location.state)}
          />
        </div>

        <Card>
          <Card.Body>
            <InfoPeluangSection data={data} />
            <InfoRABSection data={data} />
            <Formik initialValues={initialValues} onSubmit={(e) => e.preventDefault()}>
              {(formik) => (
                <>
                  <FormSection formik={formik} readOnly={true} />
                  <ListBarangJadiSection
                    dataDetail={dataDetail}
                    setDataDetail={setDataDetail}
                    formik={formik}
                    readOnly={true}
                    data={data}
                  />
                </>
              )}
            </Formik>
          </Card.Body>
        </Card>
        <CatatanApproval />
      </>
    );
  }
};

export default DetailSalesOrder;

import Services from "services";

class CloseSalesOrderApi {
  get(params) {
    return Services.get("/close_sales_order/list_closed", { params });
  }

  getSingleSO(params) {
    return Services.get("/close_sales_order/single", { params });
  }

  getSO(params) {
    return Services.get("/close_sales_order/list_order", { params });
  }

  getNomor(params) {
    return Services.get("/close_sales_order/nomor", { params });
  }

  save(data) {
    return Services.put("/close_sales_order/close", data);
  }

  getDropdownCustomer() {
    return Services.get("/dropdown/customer/");
  }

  getDropdownProyek() {
    return Services.get("/dropdown/proyek/");
  }

  getDropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi/");
  }
}

export default new CloseSalesOrderApi();

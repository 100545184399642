import { useState, useEffect } from "react";
import { Row, Dropdown, Spinner, Table } from "react-bootstrap";
import { debounce } from "lodash";
import Axios from "axios";
import FileSaver from "file-saver";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  FilterButton,
  Pagination,
  InputSearch,
  Th,
  Td,
  ThFixed,
  Tr,
  TdFixed,
  TBody,
} from "components";
import {
  PageNumber,
  DateConvert,
  DecimalConvert,
  TableNumber,
  RupiahConvert,
} from "utilities";
import { LaporanFakturPenjualanApi } from "api";
import { ModalFilter } from "./components";
import Thead from "components/Table/THead";

const LaporanFakturPenjualan = ({ setNavbarTitle }) => {
  const title = "Laporan Faktur Penjualan";
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [data, setData] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);

  const [dropdownProyek, setDropdownProyek] = useState([]);
  const [dropdownBarangJadi, setDropdownBarangJadi] = useState([]);
  const [dropdownCustomer, setDropdownCustomer] = useState([]);
  const [dropdownUnitProduksi, setDropdownUnitProduksi] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState(true);

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "detail",
    id: "",
  });
  const date = new Date();

  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: false,
      status_approval: "APP",
      tanggal_start: DateConvert(
        new Date(date.getFullYear(), date.getMonth(), 1)
      ).default,
      tanggal_end: DateConvert(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      ).default,
      nama_customer: undefined,
      nama_barang: undefined,
      nama_proyek: undefined,
      id_unit_produksi: undefined,
    },
    pagination: {
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    },
    search: { key: "" },
  });

  //MAPING DATA DROPDOWN
  const mapProyek = (data) =>
    data.map((val) => {
      return {
        label: val.nama_proyek,
        value: val.nama_proyek,
      };
    });
  const mapBarangJadi = (data) =>
    data.map((val) => {
      return {
        label: val.nama_item,
        value: val.nama_item,
      };
    });
  const mapCustomer = (data) =>
    data.map((val) => {
      return {
        label: val.nama_customer,
        value: val.nama_customer,
      };
    });

  const mapUnitProduksi = (data) =>
    data.map((val) => {
      return {
        label: val.nama_unit_produksi,
        value: val.id_unit_produksi,
      };
    });

  // FETCH DATA DROPDOWN
  const getDropdown = () => {
    setDropdownLoading(true);
    Axios.all([
      LaporanFakturPenjualanApi.getDropdown({ tipe: "proyek" }),
      LaporanFakturPenjualanApi.getDropdown({ tipe: "barang_jadi" }),
      LaporanFakturPenjualanApi.getDropdown({ tipe: "customer" }),
      LaporanFakturPenjualanApi.getDropdownUnitProduksi(),
    ])
      .then(
        Axios.spread((pro, bar, cus, unit) => {
          const dataProyek = mapProyek(pro?.data?.data ?? []);
          const dataBarangJadi = mapBarangJadi(bar?.data?.data ?? []);
          const dataCustomer = mapCustomer(cus?.data?.data ?? []);
          const dataUnitProduksi = mapUnitProduksi(unit?.data?.data ?? []);

          setDropdownProyek(dataProyek);
          setDropdownBarangJadi(dataBarangJadi);
          setDropdownCustomer(dataCustomer);
          setDropdownUnitProduksi(dataUnitProduksi);

          setDropdownLoading(false);
        })
      )
      .catch(() => <DataStatus text="Data gagal dimuat..." />)
      .finally(() => setDropdownLoading(false));
  };

  // FETCH DATA REPORT SESUAI FILTER
  const getReportData = () => {
    setIsFetchingReport(true);

    LaporanFakturPenjualanApi.getPage({
      q: dataFilter?.search?.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tanggal_start: dataFilter?.filter?.tanggal_start,
      tanggal_end: dataFilter?.filter?.tanggal_end,
      nama_customer: dataFilter?.filter?.nama_customer,
      nama_barang: dataFilter?.filter?.nama_barang,
      nama_proyek: dataFilter?.filter?.nama_proyek,
      id_unit_produksi: dataFilter?.filter?.id_unit_produksi,
    })
      .then(({ data }) => {
        setData(data?.data ?? []);
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        }));
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => setIsFetchingReport(false));
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    LaporanFakturPenjualanApi.getExport({
      tipe,
      ...dataFilter.filter,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      active: undefined,
    })
      .then((res) => {
        const data = res.data.data;
        const rawURL = data.split(":")[1];
        const urlFile =
          window.location.protocol === "http"
            ? `http:${rawURL}`
            : `https:${rawURL}`;

        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    getDropdown();

    return () => {
      setData([]);
    };
  }, [setNavbarTitle]);

  // fetch report on dataFilter change
  useEffect(() => {
    // if (dataFilter.filter.active) getReportData()
    getReportData();
  }, [
    dataFilter?.search?.key,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tanggal_start,
    dataFilter?.filter?.tanggal_end,
    dataFilter?.filter?.nama_customer,
    dataFilter?.filter?.nama_barang,
    dataFilter?.filter?.nama_proyek,
    dataFilter?.filter?.id_unit_produksi,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
  ]);

  const PageContent = () => {
    return (
      <>
        <div className="font-weight-bold mb-2">List Data Faktur Penjualan</div>

        <Table
          responsive
          bordered
          hover
          className="bg-white border-1 shadow-sm "
        >
          <Thead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Tgl.Faktur</Th>
              <Th>No.Faktur Penjualan</Th>
              <Th>No. Sales Order</Th>
              <Th>No. Surat Jalan</Th>
              <Th>No. Delivery Order</Th>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>Sales</Th>
              <Th>Unit Produksi</Th>
              <Th>Description (Barang Jadi)</Th>
              <Th>Qty.</Th>
              <Th>Harga Satuan</Th>
              <Th>Diskon</Th>
              <Th>PPN</Th>
              <Th>Jumlah</Th>
            </Tr>
          </Thead>
          <TBody>
            {data?.length > 0 ? (
              data?.map((val, index) => {
                const subTotal = val.harga_satuan_jual * val.qty;
                const totalDiskon = subTotal - (val.diskon / 100) * subTotal;
                const totalPPN = totalDiskon + (val.ppn / 100) * totalDiskon;
                return (
                  <Tr key={index}>
                    <TdFixed textCenter>
                      {TableNumber(
                        dataFilter?.pagination?.page,
                        dataFilter?.pagination?.dataLength,
                        index
                      )}
                    </TdFixed>
                    <Td>{val.tgl_faktur ?? "-"}</Td>
                    <Td>{val.no_faktur ?? "-"}</Td>
                    <Td>{val.no_sales_order ?? "-"}</Td>
                    <Td>{val.no_surat_jalan ?? "-"}</Td>
                    <Td>{val.no_delivery_order ?? "-"}</Td>
                    <Td>{val.nama_customer ?? "-"}</Td>
                    <Td>{val.nama_proyek ?? "-"}</Td>
                    <Td>{val.nama_sales ?? "-"}</Td>
                    <Td>{val.nama_unit_produksi ?? "-"}</Td>
                    <Td>{val.nama_item ?? "-"}</Td>
                    <Td>
                      {DecimalConvert(parseFloat(val.qty ?? 0)).getWithComa}
                    </Td>
                    <Td>
                      {
                        RupiahConvert(String(val.harga_satuan_jual ?? 0))
                          .getWithComa
                      }
                    </Td>
                    <Td>
                      {val.diskon
                        ? parseFloat(val.diskon ?? 0).toPrecision() + " %"
                        : "-"}
                    </Td>
                    <Td>
                      {val.ppn
                        ? parseFloat(val.ppn ?? 0).toPrecision() + " %"
                        : "-"}
                    </Td>
                    <Td>
                      {RupiahConvert(String(totalPPN ?? 0)).getWithComa ?? "-"}
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={16}
                  className="align-middle text-nowrap text-center"
                >
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
            )}
          </TBody>
        </Table>

        {/* Pagination */}
        {data?.length > 0 && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: { ...prev.pagination, page: selected + 1 },
              }))
            }
            onDataLengthChange={(e) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: {
                  ...prev.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              }))
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex ml-1 mb-3">
        <div className="mr-2 mb-1">
          <InputSearch
            placeholder="Cari barang jadi"
            onChange={debounce(
              (e) =>
                setDataFilter((prev) => ({
                  ...prev,
                  pagination: {
                    ...prev.pagination,
                    page: 1,
                  },
                  search: { key: e.target.value },
                })),
              1500
            )}
            onSubmit={(e) => e.preventDefault()}
          />
        </div>

        <FilterButton
          active={dataFilter?.filter?.active}
          onClick={() => setModalConfig({ show: true, type: "filter" })}
          className="mr-2"
        />

        {/* Export Button */}
        <Dropdown>
          <Dropdown.Toggle
            disabled={loadingExport}
            className="ml-2 mb-2 px-3 btn btn-warning text-white"
          >
            {loadingExport ? (
              <Spinner animation="border" variant="white" size="sm" />
            ) : (
              "Export Data"
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => exportData("excel")}>
              Excel (.xlsx)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => exportData("pdf")}>
              PDF (.pdf)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      {isFetchingReport ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div style={{ minHeight: "45vh" }}>
          <PageContent />
        </div>
      )}

      {/* modal filter */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilter
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
          dropdownLoading={dropdownLoading}
          dropdownCustomer={dropdownCustomer}
          dropdownBarangJadi={dropdownBarangJadi}
          dropdownProyek={dropdownProyek}
          dropdownUnitProduksi={dropdownUnitProduksi}
        />
      )}
    </CRUDLayout>
  );
};

export default LaporanFakturPenjualan;

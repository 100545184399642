import React, { forwardRef, useEffect, useState } from 'react'
import {
    CRUDLayout,
    THead,
    TBody,
    ThFixed,
    TdFixed,
    Tr,
    Th,
    Td,
    DataStatus,
} from '../../../components'
import { RupiahConvert, DateConvert } from '../../../utilities'

const PrintReturPenjualan = forwardRef((props, ref) => {
    let id = props.id;
    let action = props.action
    let data = props.dataPrint

    // FUNCTION KONVERSI TANGGAL INPUT
    const getConvertedDate = (inputDate) => {
        if (inputDate != null) {
            const getDate = inputDate.split(" ");
            const date = new Date(getDate[0]);
            return `${DateConvert(date).defaultDay}/${DateConvert(date).defaultMonth}/${DateConvert(date).defaultYear}`
            // return DateConvert(date).detail
        }
        return "-"
    }
    const getConvertedDateDetail = (inputDate) => {
        if (inputDate != null) {
            const getDate = inputDate.split(" ");
            const date = new Date(getDate[0]);
            return DateConvert(date).detail
        }
        return "-"
    }

    const getTotal = () => {
        return data?.detail?.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0)
    }

    const getDiskon = () => {
        let total = getTotal()
        return (total * data.diskon) / 100
    }

    const getTotalSetelahDiskon = () => {
        let total = getTotal()
        let jmlDiskon = getDiskon()
        return total - jmlDiskon
    }

    const getPpn = () => {
        let total = getTotal()
        return (total * data.ppn) / 100
    }

    const getTotalSetelahPpn = () => {
        let totalSetelahDiskon = getTotalSetelahDiskon()
        let jmlPpn = getPpn()
        return totalSetelahDiskon + jmlPpn
    }

    useEffect(() => {
        console.log(data)
    }, [id, data])

    return (
        <div className='container my-5 px-5' ref={ref}>
            <div className='row'>
                <div className="col">
                    <div className='row'>
                        <div className="col">
                            <h6>Tgl. Retur Penjualan</h6>
                        </div>
                        <div className="col">
                            <h6>: {getConvertedDate(data?.tgl_retur_penjualan)}</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className='row'>
                        <div className="col">
                            <h6>No. Retur Penjualan</h6>
                        </div>
                        <div className="col">
                            <h6 className="text-uppercase">: {data?.no_retur_penjualan}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className="col">
                    <div className='row'>
                        <div className="col">
                            <h6>Tgl. Sales Order</h6>
                        </div>
                        <div className="col">
                            <h6>: {getConvertedDate(data?.tgl_sales_order)}</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className='row'>
                        <div className="col">
                            <h6>No. Sales Order</h6>
                        </div>
                        <div className="col">
                            <h6 className="text-uppercase">: {data?.no_sales_order}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className="col">
                    <div className='row'>
                        <div className="col">
                            <h6>Tgl. Faktur Penjualan</h6>
                        </div>
                        <div className="col">
                            <h6>: {getConvertedDate(data?.tgl_faktur)}</h6>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className='row'>
                        <div className="col">
                            <h6>No. Faktur Penjualan</h6>
                        </div>
                        <div className="col">
                            <h6 className="text-uppercase">: {data?.no_faktur}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-3">
                <div className="row">
                    <div className="col">
                        <h6>Nama Customer</h6>
                    </div>
                    <div className="col-9">
                        <h6>: {data.nama_customer}</h6>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h6>Nama Sales</h6>
                    </div>
                    <div className="col-9">
                        <h6>: {data.nama_karyawan}</h6>
                    </div>
                </div>
            </div>

            <small className="mb-2">List Barang dari Faktur Penjualan</small>
            <CRUDLayout.Table>
                <THead>
                    <Tr className="text-center">
                        <ThFixed>No</ThFixed>
                        <ThFixed>Kode Barang</ThFixed>
                        <Th>Nama Barang</Th>
                        <Th>Nama Gudang</Th>
                        <Th>Qty</Th>
                        <Th>Satuan</Th>
                        <Th>Harga Satuan</Th>
                        <Th>Jumlah</Th>
                    </Tr>
                </THead>
                <TBody>
                    {
                        (data?.detail_so?.length > 0) ?
                            data?.detail_so?.map((val, index) => {
                                // ADD KEY sub_total PADA VAL
                                val.sub_total = String(val.harga_satuan_jual * val.qty_item);
                                return (
                                    <Tr key={val.id_sales_order_detail}>
                                        <TdFixed >{index + 1}</TdFixed>
                                        <TdFixed >{val.kode_barang}</TdFixed>
                                        <Td >{val.nama_barang}</Td>
                                        <Td >{val.nama_gudang}</Td>
                                        <Td textRight>{parseInt(val.qty_item)}</Td>
                                        <Td >{val.nama_satuan}</Td>
                                        <Td textRight>{val.harga_satuan_jual ? RupiahConvert(String(val.harga_satuan_jual)).detail : 'Rp. 0'}</Td>
                                        <Td textRight >{val.harga_satuan_jual && val.qty_item ? RupiahConvert(String(val.harga_satuan_jual * val.qty_item)).detail : 'Rp. 0'}</Td>
                                    </Tr>
                                );
                            }) : <Tr>
                                <td colSpan="7">
                                    <DataStatus text="Tidak ada data" />
                                </td>
                            </Tr>
                    }
                </TBody>
            </CRUDLayout.Table>

            {/* TABLE ITEM RETUR */}
            <small className="mb-1">List Barang Retur</small>
            <CRUDLayout.Table>
                <THead>
                    <Tr className="text-center">
                        <ThFixed>No</ThFixed>
                        <ThFixed>Kode Barang</ThFixed>
                        <Th>Nama Barang</Th>
                        <Th>Nama Gudang</Th>
                        <Th>Qty</Th>
                        <Th>Satuan</Th>
                        <Th>Harga Satuan</Th>
                        <Th>Jumlah</Th>
                    </Tr>
                </THead>
                <TBody>
                    {
                        (data?.detail_retur?.length > 0) ?
                            data?.detail_retur?.map((val, index) => {
                                // ADD KEY sub_total PADA VAL
                                val.sub_total = String(val.harga_satuan_jual * val.qty_retur_penjualan);
                                return (
                                    <Tr key={val.id_sales_order_detail}>
                                        <TdFixed >{index + 1}</TdFixed>
                                        <TdFixed >{val.kode_barang}</TdFixed>
                                        <Td >{val.nama_barang}</Td>
                                        <Td >{val.nama_gudang}</Td>
                                        <Td textRight>{parseInt(val.qty_retur_penjualan)}</Td>
                                        <Td >{val.nama_satuan}</Td>
                                        <Td textRight>{val.harga_satuan_jual ? RupiahConvert(String(val.harga_satuan_jual)).detail : 'Rp. 0'}</Td>
                                        <Td textRight>{val.sub_total ? RupiahConvert(String(val.sub_total)).detail : 'Rp. 0'}</Td>
                                        {/* <Td textRight >{RupiahConvert(String(val.harga_satuan_jual * val.qty_item)).detail}</Td> */}
                                    </Tr>
                                );
                            }) : <Tr>
                                <td colSpan="7">
                                    <DataStatus text="Tidak ada data" />
                                </td>
                            </Tr>
                    }
                </TBody>
            </CRUDLayout.Table>

            <div>
                <h6>Catatan:</h6>
                <p>{data?.catatan_retur_penjualan ?? 'Tidak ada catatan'}</p>
            </div>

            {/* FOOTER SIGNATURE*/}
            {/* <div className="fixed-bottom container pb-5" style={{ height: "15vh" }}>
                <div className="d-flex flex-row justify-content-end pb-5 pr-5">
                    <h5>{`Tgl. ${(action === "terima") ? "Penerimaan" : "Pembatalan"}: ${getConvertedDateDetail((action === "terima") ? data?.tgl_penerimaan_faktur : data?.tgl_pembatalan_faktur)}`}</h5>
                </div>

                <div className="row pb-5">
                    <div className="col d-flex flex-column justify-content-between align-items-center ">
                        <h5>Sales</h5>
                        <div className="d-flex mt-5 flex-column justify-content-end align-items-end ">
                            <pre style={{ border: "none" }}>(                 )</pre>
                        </div>
                    </div>
                    <div className="col  d-flex flex-column justify-content-between align-items-center ">

                    </div>
                    <div className="col d-flex flex-column justify-content-between align-items-center ">
                        <h5>Customer</h5>
                        <div className="d-flex mt-5 flex-column justify-content-end align-items-end ">
                            <pre style={{ border: "none" }}>(                 )</pre>
                        </div>
                    </div>
                </div>
            </div> */}
        </div >
    );

})

export default PrintReturPenjualan
import { DataStatus, Table, Td, TdFixed, Th } from "components"
import { ReactNumberFormat } from "components/Input"
import { useState } from "react"
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities"

const TableSection = ({ formik, dataTable, setDataTable, data }) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const { values, setFieldValue } = formik;

  const onCheckedHandler = (index) => {
    const newData = dataTable.map((val, i) => {
      return i === index ? { ...val, checked: !val.checked } : { ...val };
    });

    setDataTable(newData);
  };

  const onCheckedAllHandler = () => {
    const newData = dataTable.map((val) => {
      return { ...val, checked: !isAllChecked };
    });

    setDataTable(newData);
    setIsAllChecked(!isAllChecked);
  };

  const getTotalFooter = () => {
    const getTotal = () =>
      dataTable.reduce((prev, current) => {
        let total = 0;

        if (current.checked) {
          total = parseFloat(
            parseFloat(current.qty) * parseFloat(current.harga)
          );
        }

        return prev + total;
      }, 0);

    const getDiskon = () => {
      const total = getTotal();
      const diskon = values.diskon;
      const totalDiskon = (total / 100) * diskon;

      return totalDiskon;
    };

    const getTotalAfterDiskon = () => {
      const total = getTotal();
      const totalDiskon = getDiskon();

      return total - totalDiskon;
    };

    const getPPN = () => {
      const totalAfterDiskon = getTotalAfterDiskon();
      const ppn = values.ppn;
      const totalPPN = (totalAfterDiskon / 100) * ppn;

      return totalPPN;
    };

    const getTotalAfterPPN = () => {
      const ppn = getPPN();
      const totalAfterDiskon = getTotalAfterDiskon();

      return totalAfterDiskon + ppn;
    };

    return {
      total: RupiahConvert(getTotal().toString()).getWithComa,
      diskon: RupiahConvert(getDiskon().toString()).getWithComa,
      ppn: RupiahConvert(getPPN().toString()).getWithComa,
      totalAfterDiskon: RupiahConvert(getTotalAfterDiskon().toString())
        .getWithComa,
      totalAfterPPN: RupiahConvert(getTotalAfterPPN().toString()).getWithComa,
    };
  };

  const Checkbox = ({ checked, onClick }) => (
    <div className="form-check" onClick={onClick}>
      <input
        type="checkbox"
        className="form-check-input mt-0"
        style={{ width: 20, height: 20 }}
        defaultChecked={checked}
      />
    </div>
  );

  console.log(dataTable);
  return (
    <>
      <div className="p-1">
        <b>List Surat Jalan</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <Th>Tgl. Surat Jalan</Th>
          <Th>No. Surat Jalan</Th>
          <Th>Referensi Surat Jalan</Th>
          <Th>No. Delivery Order</Th>
          <Th width={300}>Item Barang</Th>
          <Th>Driver</Th>
          <Th>Kendaraan</Th>
          <Th>Qty</Th>
          <Th>Harga Satuan</Th>
          <Th>Jumlah</Th>
          <Th className="pt-0">
            <div className="mb-3 d-flex justify-content-center align-items-center">
              <Checkbox
                checked={isAllChecked}
                onClick={() => onCheckedAllHandler()}
              />
            </div>
          </Th>
        </thead>
        <tbody>
          {dataTable.length > 0 ? (
            dataTable.map((val, index) => {
              return (
                <tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>
                    {val.tgl_surat_jalan
                      ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY
                      : "-"}
                  </Td>
                  <Td>{val.no_surat_jalan}</Td>
                  <Td>{val.referensi_surat_jalan ?? "-"}</Td>
                  <Td>{val.no_delivery_order}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.driver ?? "-"}</Td>
                  <Td>{val.kendaraan ?? "-"}</Td>
                  <Td textRight>
                    {DecimalConvert(val.qty).getWithComa} {val.satuan}
                  </Td>
                  <Td textRight>
                    {
                      RupiahConvert(parseFloat(val.harga).toString())
                        .getWithComa
                    }
                  </Td>
                  <Td textRight>
                    {
                      RupiahConvert(parseFloat(val.qty * val.harga).toString())
                        .getWithComa
                    }
                  </Td>
                  <Td className="p-0">
                    <div className="d-flex justify-content-center align-items-center">
                      <Checkbox
                        checked={val.checked}
                        onClick={() => onCheckedHandler(index)}
                      />
                    </div>
                  </Td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
        <tfoot style={{ fontSize: 13 }}>
          {/* Total */}
          <tr className="bg-light">
            <td
              colSpan={10}
              className="p-2 text-right font-weight-bold align-middle"
            >
              Total
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().total}
            </td>
            <td></td>
          </tr>
          {/* Diskon */}
          <tr>
            <td colSpan={10} className="p-0 font-weight-bold">
              <div className="d-flex justify-content-end align-items-center">
                <div className="mx-2">Diskon</div>
                {/* <input
                  className="form-control form-control-sm"
                  style={{ width: 60 }}
                  value={values.diskon}
                  onChange={(e) => {
                    const value = e.target.value;
                    const convert = decimalConvert(value);
                    setFieldValue("diskon", convert);
                  }}
                /> */}
                <ReactNumberFormat
                  style={{ width: 60 }}
                  value={values.diskon}
                  onChange={(e) => {
                    setFieldValue("diskon", e);
                  }}
                />
              </div>
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().diskon}
            </td>
            <td></td>
          </tr>
          {/* Total Setelah Diskon */}
          <tr className="bg-light">
            <td
              colSpan={10}
              className="p-2 text-right font-weight-bold align-middle"
            >
              Total Setelah Diskon
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().totalAfterDiskon}
            </td>
            <td></td>
          </tr>
          {/* PPN */}
          <tr>
            <td colSpan={10} className="p-0 font-weight-bold">
              <div className="d-flex justify-content-end align-items-center">
                <div className="mx-2">PPN</div>
                {/* <input
                  className="form-control form-control-sm"
                  style={{ width: 60 }}
                  value={values.ppn}
                  onChange={(e) => {
                    const value = e.target.value;
                    const convert = decimalConvert(value);
                    setFieldValue("ppn", convert);
                  }}
                /> */}
                <ReactNumberFormat
                  style={{ width: 60 }}
                  value={values.ppn}
                  onChange={(e) => {
                    setFieldValue("ppn", e);
                  }}
                />
              </div>
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().ppn}
            </td>
            <td></td>
          </tr>
          {/* Total Setelah PPN */}
          <tr className="bg-light">
            <td
              colSpan={10}
              className="py-2 px-1 text-right font-weight-bold align-middle"
            >
              Total Setelah PPN
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().totalAfterPPN}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default TableSection;
